import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { Col, Container, Form, Nav, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BreadCrumbPage from "../../../../common/bread-crumb-page";
import {
  navContentStyle,
  navStyle,
  tabDivStyle,
} from "../../../../common/components-style";
import {
  useStoreActions,
  useStoreState,
} from "../../../../store/models/model-hook";
import { SpinLoader } from "../../../../utils/spin-loader";
import BrandPropertySideMenu from "../brand-property-side-menu";

const BrandPropertyDetails: React.FC = (): JSX.Element => {
  let { id, propertyId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { getPropertyById } = useStoreActions((actions) => ({
    getPropertyById: actions.propertyModel.getPropertyById,
  }));

  const { Client, Property } = useStoreState((state) => ({
    Client: state.clientModel.Client,
    Property: state.propertyModel.Property,
  }));
  const { IsLoading } = useStoreState((state) => ({
    IsLoading: state.propertyModel.IsLoading,
  }));

  useEffect(() => {
    (async function () {
      await getPropertyById({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
        id: propertyId,
      });
    })();
  }, [getPropertyById, id, user.attributes?.email, propertyId]);

  return (
    <>
      <SpinLoader isLoading={IsLoading} />
      <div style={tabDivStyle}>
        <Row>
          <Col sm={2}>
            <Nav
              variant="pills"
              className="flex-column secondry-menu "
              style={navStyle}
            >
              <BrandPropertySideMenu clientId ={id} propertyId={propertyId} />
            </Nav>
          </Col>
          <Col sm={10}>
            <div style={navContentStyle}>
              <BreadCrumbPage
                selectedBrandCrumb="brandManagement"
                activateName={`${Property?.name} - ${Property?.city}`}
                secondName={Client?.name} 
                secondNameId={Client?.id}
                secondURL="brand-properties/"
              />
              <div>
                <Row style={{ marginTop: "3%" }}>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <Container>
                      <div className="jumbotron">
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Name</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.name}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Address 1</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.address_1}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Address 2</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.address_2}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>City</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.city}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Country</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.country}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Postal Code</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.post_code}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Email Address</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.email}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Contact</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.contact}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} style={{ textAlign: "right" }}>
                            <Form.Label>Phone Number</Form.Label>
                          </Col>
                          <Col md={6} style={{ textAlign: "left" }}>
                            <p className="lead">{Property?.phone}</p>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BrandPropertyDetails;
