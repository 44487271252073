import { action, thunk } from "easy-peasy";
import moment from "moment";
import axios from "../../../../common/axios";
import NotificationAlert from "../../../../common/notification-alert";
import IBrandSurveyAction from "./IBrandSurveyAction";
import IBrandSurveys from "./IBrandSurveys";
import IBrandSurveyThunk from "./IBrandSurveyThunk";

interface IBrandSurveyModel
  extends IBrandSurveys,
    IBrandSurveyAction,
    IBrandSurveyThunk {}

const brandSurveyModel: IBrandSurveyModel = {
  BrandSurvey: {
    name: "",
    programme: "",
    description: "",
    validFor: 0,
    completionMethod: 0,
    sections: [],
    certificateCount: 0,
    templateCount: 0,
    canDelete: false,
    completedOn: "",
    startDate: ""
  },
  BrandSurveys: [],
  IsLoading: false,
  SurveyNames: [],
  getSurveysByBrand: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/search", payload)
      .then((value: any) => {
        let tempBrandSurveys: IBrandSurveys = {
          BrandSurveys: [],
          BrandSurvey: {
            name: "",
            programme: "",
            description: "",
            validFor: 0,
            completionMethod: 0,
            sections: [],
            certificateCount: 0,
            templateCount: 0,
            canDelete: false,
            completedOn: "",
            startDate: ""
          },
          IsLoading: false,
          SurveyNames: [],
        };

        value.data.certificates.forEach((certi: any) => {
          certi.surveys.forEach((element: any, index: number) => {
            if (element && element.survey) {
              tempBrandSurveys.BrandSurveys?.push({
                key: `${element.survey.id}_${index}`,
                surveyId: element.survey.id,
                programme: element.survey.programme,
                clientName: element.survey.corporate_client,
                rfpDate:
                  element.survey.rfp_date == null
                    ? element.survey.programme
                    : moment(element.survey.rfp_date).format("DD/MM/yyyy"),
                    rfp_EndDate:
                        element.survey.rfp_EndDate == null
                        ? ""
                        : moment(element.survey.rfp_EndDate).format("DD/MM/yyyy"),
                surveyName: element.survey.name,
                isCompleted: element.isCompleted,
                certificateId: certi.id,
                 completedOn :element.completedOn == null
                 ? "": moment(element.completedOn).format("DD/MM/yyyy"),
              });
            }
          });
        });

        action.setSurveysByBrand(tempBrandSurveys);
        if (payload.isFilterRequired) {
          action.setAllSurveyForFilter(value.data.surveyNames);
        }
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  setSurveysByBrand: action((state, payload) => {
    state.BrandSurveys = payload.BrandSurveys;
  }),

  setAllSurveyForFilter: action((state, payload) => {
    state.SurveyNames= payload;
  }),

  getSurveyDetailsByCertificate: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/certificate/view", payload)
      .then((value: any) => {
        let tempBrandSurvey: IBrandSurveys = {
          BrandSurveys: [],
          BrandSurvey: {
            name: "",
            programme: "",
            description: "",
            validFor: 0,
            completionMethod: 0,
            sections: [],
            certificateCount: 0,
            templateCount: 0,
            canDelete: false,
            completedOn: "",
            startDate: ""
          },
          IsLoading: false,
          SurveyNames: [],
        };

        if (value.data.certificate && value.data.certificate.surveys) {
          var surveyDetails = value.data.certificate.surveys.find(function (
            element: any
          ) {
            return element.surveyID === payload.surveyId;
          });

          if (surveyDetails) {
            tempBrandSurvey.BrandSurvey = {
              name: surveyDetails.survey.name,
              programme: surveyDetails.survey.programme,
              description: surveyDetails.survey.description,
              validFor: 0,
              completionMethod: 0,
              sections: surveyDetails.survey.sections,
              certificateCount: 0,
              templateCount: 0,
              canDelete: false,
              expectedCompletedDate: surveyDetails.expectedCompletedDate,
              completedOn: surveyDetails.completedOn,
              startDate: surveyDetails.startDate
            };
          }
        }

        action.setSurveyDetailsByBrand(tempBrandSurvey.BrandSurvey);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  setSurveyDetailsByBrand: action((state, payload) => {
    state.BrandSurvey = payload;
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default brandSurveyModel;
