import React, { useCallback, useEffect } from "react";
import useState from "react-usestateref"; // see this line
import { Button, Col, Container, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IQuestion } from "../../store/models/survey/IQuestion";
import { ISurveyResponse } from "../../store/models/survey/ISurveyResponse";
import ISurveySection from "../../store/models/survey/ISurveySection";
import StringListEdit from "./string-list-edit";
import "./survey.css";
import { ICheckListModel } from "../../store/models/common/check-list-model";
import Upload from "rc-upload";
import uploadSvg from "../../images/upload.svg"
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import UploadDocumentPopUp from "./upload-document-popup";
import { render } from "@testing-library/react";
import UploadDocumentPopup from "./upload-document-popup";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { X } from "react-bootstrap-icons";
import { IObjectAttachment } from "../../store/models/survey/IObjectAttachment";

const QuestionAddEditPopUp: React.FC<any> = (props): JSX.Element => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  //   setValue,
  //   clearErrors,
  // } = useForm();
  const methods = useForm({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = methods;
  
  const handleClose = () => {
    props.closeModal();
  };
  const [condition, setCondition] = useState<boolean>(false);
  const [conditionText, setConditionText] = useState<string>("No");
  const [isAnswer, setIsAnswer] = useState<boolean>(false);
  const [isAnswerText, setIsAnswerText] =
    useState<string>("Ask if answered No");
  const [questionType, setQuestionType] = useState<string>("");
  const [mandatory, setMandatory] = useState<boolean>(true);
  const [mandatoryText, setMandatoryText] = useState<string>("Yes");
  const [listOption, setListOption] = useState<string>("");
  const [opts, setOpts] = useState<any>();
  const [conditionValue, setConditionsValue] = useState<any>();
  const [validationOptions, setValidationOptions] = useState<any>();
  const [validationSwitch, setValidationSwitch] = useState<any>();
  const [isValidationSwitch, setIsValidationSwitch] = useState<boolean>(false);
  const [validationSwitchText, setValidationSwitchText] =
    useState<string>("No");

  const [isGPSRequiredSwitch, setIsGPSRequiredSwitch] =
    useState<boolean>(false);
  const [GPSRequiredSwitchText, setGPSRequiredSwitchText] =
    useState<string>("GPS Not Required");
  const [isImageProximitySwitch, setIsImageProximitySwitch] =
    useState<boolean>(false);
  const [ImageProximitySwitchText, setImageProximitySwitchText] =
    useState<string>("No");
  const [isImageRecentSwitch, setIsImageRecentSwitch] =
    useState<boolean>(false);
  const [ImageRecentSwitchText, setImageRecentSwitchText] =
    useState<string>("No");

  const [selectedSurvey, setSelectedSurvey] = useState<ISurveyResponse>();
  const [selectedSection, setSelectedSection] = useState<ISurveySection>();
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();
  const [sections, setSections, sectionsRef] = useState<ISurveySection[]>();
  const [questions, setQuestions, questionsRef] = useState<IQuestion[]>();
  const [confirmationApproveModalShow, setConfirmationApproveModalShow] = useState(false);
  const handleConfirmationApproveModalShow = () => setConfirmationApproveModalShow(true);
  const handleConfirmationApproveModalClose = () => {
    setConfirmationApproveModalShow(false);
  };

  const [AddDocument, setShowAddDocument] = useState(false);
  const handleCloseDocument = () => setShowAddDocument(false);
  const handleAddDocument = () => setShowAddDocument(true);
  const handleQuestionSelect = useCallback(
    (e: any) => {
      if (e) {
        const questionSel = questions?.find(
          (question: any) => question.id === e
        );
        setSelectedQuestion(questionSel);
      } else {
        setSelectedQuestion(undefined);
      }
    },
    [questions]
  );

  const handleSectionSelect = useCallback(
    (e: any) => {
      if (e) {
        const surveySel = sections?.find((section: any) => section.id === e);
        setSelectedSection(surveySel);
        setQuestions(surveySel?.questions);
        setSelectedQuestion(undefined);
      } else {
        setSelectedSection(undefined);
        setQuestions([]);
        setSelectedQuestion(undefined);
      }

      handleQuestionSelect(undefined);
      setValue("questionConditionQuestion", "");
    },
    [handleQuestionSelect, sections, setQuestions, setValue]
  );

  const handleSurveySelect = useCallback(
    (e: any) => {
      // if (e) {
      // const surveySel = props.allSurveys.find(
      //   (survey: any) => survey.id === e
      // );
      //   setSelectedSurvey(surveySel);
      //   setSections(surveySel.sections);
      //   setSelectedSection(undefined);
      // } else {
      //   setSelectedSurvey(undefined);
      //   setSections([]);
      //   setSelectedSection(undefined);
      // }

      if (e) {
        setSelectedSurvey(props.currentSurvey);
        setSections(props.currentSurvey.sections);
        setSelectedSection(undefined);
      } else {
        setSelectedSurvey(undefined);
        setSections([]);
        setSelectedSection(undefined);
      }

      handleSectionSelect(undefined);
      setValue("questionConditionSection", "");
      setValue("questionConditionQuestion", "");
    },
    [handleSectionSelect, setValue, setSections, props.currentSurvey]
  );

  const handleDDOnEdit = () => {
    const surveySel = props.allSurveys.find(
      (survey: any) => survey.id === props.question?.conditionalSurveyID
    );
    setSelectedSurvey(surveySel);
    setValue("questionConditionSurvey", props.question?.conditionalSurveyID);
    setSections(surveySel.sections);

    const sectionSel = sectionsRef?.current?.find(
      (section: any) => section.id === props.question?.conditionalSectionID
    );
    setSelectedSection(sectionSel);
    setValue("questionConditionSection", props.question?.conditionalSectionID);
    setQuestions(sectionSel?.questions);

    const questionSel = questionsRef?.current?.find(
      (question: any) => question.id === props.question?.conditionalQuestionID
    );
    setSelectedQuestion(questionSel);
    setValue(
      "questionConditionQuestion",
      props.question?.conditionalQuestionID
    );

    if (questionSel?.type === 0) {
      setIsAnswer(props.question?.conditionalValue === "True");
      setIsAnswerText(
        props.question?.conditionalValue === "True"
          ? "Ask if answered Yes"
          : "Ask if answered No"
      );
    } else if (questionSel?.type === 5) { //One of
      setValue("conditionalValue", props.question?.conditionalValue);
    } else if (questionSel?.type === 6) { //Many of
      // if(props.question?.conditionalValue?.includes(";"))
      // {
      const selectedCheckOptions = props.question?.conditionalValue?.split(";");
      let i = 0;
      var tmp: any = [];
      for (i = 0; i < selectedCheckOptions.length; i++) {
        tmp.push(selectedCheckOptions[i]);
      }
      setValue("conditionalValue",  tmp);
    // }
  }
  };

  const resetQuestionForm = useCallback(async () => {
    const result = {
      questionDescription: "",
      questionType: "0",
      validationMin: "",
      validationMax: "",
      validationFailMessage: "",
      options: "",
      Attachment: "",
      recentleaway: 7,
    };

    setQuestionType("0");
    setCondition(false);
    setConditionText("No");
    setMandatory(true);
    setMandatoryText("Yes");
    setValue("questionConditionSurvey", "");
    setSelectedSurvey(undefined);
    setSelectedSection(undefined);
    setSelectedQuestion(undefined);
    setSections([]);
    setQuestions([]);
    setIsValidationSwitch(false);
    setValidationSwitchText("No");
    setIsGPSRequiredSwitch(false);
    setGPSRequiredSwitchText("GPS Not Required");
    setIsImageProximitySwitch(false);
    setImageProximitySwitchText("No");
    setIsImageRecentSwitch(false);
    setImageRecentSwitchText("No");
    setIsAnswer(false);
    setIsAnswerText("Ask if answered No");

    reset(result);
  }, [
    reset,
    setCondition,
    setConditionText,
    setMandatory,
    setMandatoryText,
    setQuestions,
    setSections,
    setValue,
  ]);
  const { user } = useAuthenticator((context) => [context.user]);


  useEffect(() => {
    if (props.show) {
      if (props.isEditModal) {
        //Edit Question
        setValue("questionDescription", props.question?.description);
        setValue("questionType", props.question?.type);
        setQuestionType(props.question?.type?.toString());
        setMandatory(props.question?.mandatory);
        setMandatoryText(props.question?.mandatory ? "Yes" : "No");
        setCondition(props.question?.conditional);
        setConditionText(props.question?.conditional ? "Yes" : "No");
        setIsValidationSwitch(props.question?.validate);
        setValidationSwitchText(props.question?.validate ? "Yes" : "No");
        setValue("options", props.question?.options);
        if (props.question?.type === 11) {
          setListOption(props.question?.options);
        }
        setValue("validationMax", props.question?.validationMax);
        setValue("validationMin", props.question?.validationMin);
        setValue(
          "validationFailMessage",
          props.question?.validationFailMessage
        );
        setValue("Attachment", props.question?.options);
        if (props.question?.conditional) {
          handleDDOnEdit();
        } else {
          setValue("questionConditionSurvey", "");
          setSelectedSurvey(undefined);
          setSelectedSection(undefined);
          setSelectedQuestion(undefined);
          setSections([]);
          setQuestions([]);
        }
      } else {
        //Add Question
        resetQuestionForm();
      }
    }
  }, [props.show]);

  const onQuestionFormSubmit = (question: any) => {
    question.mandatory = mandatory;
    question.condition = condition;
    if (selectedQuestion?.type === 0) {
      question.conditionValue = isAnswer ? "True" : "False";
    } else if (selectedQuestion?.type === 5) {
      question.conditionValue = question.conditionalValue;
    } else if (selectedQuestion?.type === 6) {
      if(typeof(question.conditionalValue) != 'string')
      {
      question.conditionValue = question.conditionalValue?.join(";");
      }
      else
      {
        question.conditionValue = question.conditionalValue;
      }
    }

    if (questionType === "1" || questionType === "2") {
      question.validate = isValidationSwitch;
    } else {
      question.validate = false;
    }

    if (questionType === "8") {
      question.options = question.Attachment;
    }

    if (questionType === "10") {
      question.options = question.checkbox?.join(";");
    }

    if (questionType === "11") {
      question.options = listOption;
    }

    if (questionType === "12") {
      question.gpsTagged = isGPSRequiredSwitch;
      question.gpsRingfenced = isImageProximitySwitch;
      question.recentPicture = isImageRecentSwitch;
    }

    question.recentleaway = parseInt(question.recentleaway || 7, 10);
    question.validationMin = parseInt(question.validationMin || 0, 10);
    question.validationMax = parseInt(question.validationMax || 0, 10);
    props.manageQuestion(question);
  };

  const handleToggleChange = useCallback(
    (e: any) => {
      switch (e.target.id) {
        case "mandatory":
          if (e.target.checked) {
            setMandatoryText("Yes");
            setMandatory(true);
          } else {
            setMandatoryText("No");
            setMandatory(false);
          }
          break;
        case "conditional":
          if (e.target.checked) {
            setCondition(true);
            setConditionText("Yes");
          } else {
            setCondition(false);
            setConditionText("No");
            setValue("questionConditionSurvey", "");
            clearErrors("questionConditionSurvey");
          }
          break;
        case "conditional1":
          if (e.target.checked) {
            setIsAnswer(true);
            setIsAnswerText("Ask if answered Yes");
          } else {
            setIsAnswer(false);
            setIsAnswerText("Ask if answered No");
          }
          break;
        case "isValidationSwitch":
          if (e.target.checked) {
            setIsValidationSwitch(true);
            setValidationSwitchText("Yes");
          } else {
            setIsValidationSwitch(false);
            setValidationSwitchText("No");
          }
          break;
        case "isGPSRequired":
          if (e.target.checked) {
            setIsGPSRequiredSwitch(true);
            setGPSRequiredSwitchText("GPS Required");
          } else {
            setIsGPSRequiredSwitch(false);
            setGPSRequiredSwitchText("GPS Not Required");
          }
          break;
        case "isImageProximitySwitch":
          if (e.target.checked) {
            setIsImageProximitySwitch(true);
            setImageProximitySwitchText("Yes");
          } else {
            setIsImageProximitySwitch(false);
            setImageProximitySwitchText("No");
          }
          break;
        case "isImageRecentSwitch":
          if (e.target.checked) {
            setIsImageRecentSwitch(true);
            setImageRecentSwitchText("Yes");
          } else {
            setIsImageRecentSwitch(false);
            setImageRecentSwitchText(" No");
          }
          break;
        case "isAnswerYesNo":
          if (e.target.checked) {
            setMandatoryText("Yes");
            setMandatory(true);
          } else {
            setMandatoryText("No");
            setMandatory(false);
          }
          break;
      }
    },
    [clearErrors, setValue]
  );

  // const options = [
  //   { id: 1, name: "Option1" },
  //   { id: 2, name: "Option2" },
  //   { id: 3, name: "Option3" },
  // ];

  const [checkedList, setCheckedList] = useState<ICheckListModel[]>();

  const changeList = useCallback(
    (id: any, checked: any) => {
      const newCheckedList = toggleOption(checkedList, id, checked);
      setCheckedList(newCheckedList);
    },
    [checkedList]
  );

  function uncheckAll(options: any) {
    return options.map((option: any) => ({
      ...option,
      checked: false,
    }));
  }

  function toggleOption(objArray: any, id: any, checked: any) {
    return objArray.map((option: any) =>
      option.id === id ? { ...option, checked } : option
    );
  }

  useEffect(() => {
    setOpts(<></>);
    setValidationSwitch(<></>);
    setValidationOptions(<></>);
    switch (questionType) {
      case "1":
        setOpts(
          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label">Placeholder </label>
              <input
                {...register("options")}
                className="form-control"
                autoComplete="off"
              />
            </Col>
          </Row>
        );

        setValidationSwitch(
          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label">Validate the Answer</label>
              <Form.Check
                type="switch"
                id="isValidationSwitch"
                label={validationSwitchText}
                onChange={handleToggleChange}
                checked={isValidationSwitch}
              />
            </Col>
          </Row>
        );

        if (isValidationSwitch || false) {
          setValidationOptions(
            <>
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">Minimum Text Length</label>
                  <input
                    {...register("validationMin")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
                <Col md={6}>
                  <label className="form-label">Maximum Text Length</label>
                  <input
                    {...register("validationMax")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <label className="form-label">Fail Message</label>
                  <input
                    {...register("validationFailMessage")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </>
          );
        }

        break;
      case "2":
        setValidationSwitch(
          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label">Validate the Answer</label>
              <Form.Check
                type="switch"
                id="isValidationSwitch"
                label={validationSwitchText}
                onChange={handleToggleChange}
                checked={isValidationSwitch}
              />
            </Col>
          </Row>
        );

        if (isValidationSwitch || false) {
          setValidationOptions(
            <>
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">Minimum Value</label>
                  <input
                    {...register("validationMin")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
                <Col md={6}>
                  <label className="form-label">Maximum Value</label>
                  <input
                    {...register("validationMax")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <label className="form-label">Fail Message</label>
                  <input
                    {...register("validationFailMessage")}
                    className="form-control"
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </>
          );
        }
        break;

      case "5":
      case "6":
        setOpts(
          <Row className="mb-3">
            <Col md={6}>
              <label className="form-label">
                Options, separated by a semicolon (;)
              </label>
              <input
                {...register("options")}
                className="form-control"
                autoComplete="off"
              />
            </Col>
          </Row>
        );
        break;
      case "8":
        setOpts(
          <Row className="mb-3">
            {/* <Col md={6}>
              <label className="form-label">
                Which of your attachments do they have to read?
              </label>
              <select {...register("Attachment")} className="form-select">
                <option value="" key={0}> Select an attachment </option>
                {props.allAttachments[0] &&
                  props.allAttachments[0].map((e: any, index: number) => {
                    return (
                      <option value={e.blobname} key={e.blobname}>
                        {e.name}
                      </option>
                    )
                  })
                }
              </select> */}
              <Col md={6}>
              <input accept='application/pdf' 
                  {...register("File")}
                    type="file"
                    id="upload-button"
                  /><br/>
                   {errors.File && (
                    <span className="error">This field is required</span>
                  )}
              </Col>
            {/* </Col> */}
            {/* <Col md={6} style={{ textAlign: "right", marginTop: "55px" }}>
              <Button
                type="button"
                id="approve-button"
                className="create-btn"
                onClick={handleAddDocument}
              >
                Upload File
              </Button> */}
              {/* <Button
                id="approve-button"
                className="create-btn"
                onClick={() => {
                  render(<UploadDocumentPopUp />);
                }}
              >
                Upload File
              </Button> */}
            {/* </Col> */}
          </Row>
        );
        break;
      case "10":
        setOpts(
          <Row className="mb-3">
            <Col md={12}>
              <label className="form-label">What do you want included?</label>
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("checkbox")}
                  id="first-name"
                  className="form-check-input"
                  value="0"
                />
                <label className="form-check-label" htmlFor="first-name">
                  First Name
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("checkbox")}
                  id="last-name"
                  className="form-check-input"
                  value="1"
                />
                <label className="form-check-label" htmlFor="last-name">
                  Last Name
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("checkbox")}
                  id="phone-number"
                  className="form-check-input"
                  value="2"
                />
                <label className="form-check-label" htmlFor="phone-number">
                  Phone Number
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  {...register("checkbox")}
                  id="email-address"
                  className="form-check-input"
                  checked={true}
                  disabled={true}
                  value="3"
                />
                <label className="form-check-label" htmlFor="email-address">
                  Email Address
                </label>
              </div>
            </Col>
          </Row>
        );
        break;
      case "11":
        setOpts(
          <>
            <StringListEdit
              onChange={(newval: string[]) => {
                setListOption(newval.join(";"));
              }}
              list={listOption.split(";")}
            />
          </>
        );
        break;
      case "12":
        setOpts(
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Check
                  type="switch"
                  id="isGPSRequired"
                  label={GPSRequiredSwitchText}
                  onChange={handleToggleChange}
                  checked={isGPSRequiredSwitch}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <label className="form-label">
                  Image must be taken in proximity to the property
                </label>
                <Form.Check
                  type="switch"
                  id="isImageProximitySwitch"
                  label={ImageProximitySwitchText}
                  onChange={handleToggleChange}
                  checked={isImageProximitySwitch}
                  disabled={!isGPSRequiredSwitch}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <label className="form-label">Image must be recent</label>
                <Form.Check
                  type="switch"
                  id="isImageRecentSwitch"
                  label={ImageRecentSwitchText}
                  onChange={handleToggleChange}
                  checked={isImageRecentSwitch}
                />
              </Col>
              <Col md={6}>
                <label className="form-label">
                  How many days leaway to allow
                </label>
                <input
                  {...register("recentleaway", { min: 0, max: 90 })}
                  className="form-control"
                  autoComplete="off"
                  type="number"
                  disabled={!isImageRecentSwitch}
                />
              </Col>
            </Row>
          </>
        );
        break;

      case "14":
      case "15":
        setOpts(<></>);
        break;
    }
  }, [
    questionType,
    validationSwitchText,
    isValidationSwitch,
    GPSRequiredSwitchText,
    isGPSRequiredSwitch,
    ImageProximitySwitchText,
    ImageRecentSwitchText,
    isImageProximitySwitch,
    isImageRecentSwitch,
    register,
    props.allAttachments,
    listOption,
    handleToggleChange,
  ]);

  useEffect(() => {
    setConditionsValue(<></>);
    // var id = props.question?.conditionalQuestionID;
    if (selectedSection != null) {
      if (questions != null) {
        var q = questions.find((i) => {
          return i.id === selectedQuestion?.id;
        });
        if (q != null) {
          switch (q.type) {
            case 0:
              setConditionsValue(
                <Row className="mb-3">
                  <Col md={6}>
                    <label className="form-label">
                      How should they have answered?
                    </label>
                    <Form.Check
                      type="switch"
                      id="conditional1"
                      label={isAnswerText}
                      onChange={handleToggleChange}
                      checked={isAnswer}
                    />
                  </Col>
                </Row>
                //   <Toggle
                //     label={"How should they have answered?"}
                //     checked={this.state.question.conditionalValue === "True"}
                //     onText={this.props.intl.formatMessage({
                //       id: "question.conditional.bool.true",
                //     })}
                //     offText={this.props.intl.formatMessage({
                //       id: "question.conditional.bool.false",
                //     })}
                //     onChange={() => {
                //       var q = this.state.question;
                //       q.conditionalValue =
                //         q.conditionalValue === "True" ? "False" : "True";
                //       this.setState({ question: q, shouldSave: true });
                //     }}
                //   />
              );
              break;
            case 5: // One of
              const radioOptions = q.options.split(";").map((s, i) => {
                return {
                  key: i.toString(),
                  text: s,
                };
              });

              // setCheckedList(radioOptions);

              setConditionsValue(
                <>
                  <Row className="mb-3">
                    <Col md={12}>
                      <label className="form-label">Pick one</label>
                      {radioOptions &&
                        radioOptions.map((e: any) => (
                          <>
                            <div className="form-check">
                              <input
                                {...register("conditionalValue")}
                                type="radio"
                                value={e.text}
                                className="form-check-input"
                                id={e.key}
                                key={e.key}
                                onChange={(event) =>
                                  changeList(e.key, event.target.checked)
                                }
                              />
                              <label className="form-label" htmlFor={e.key}>
                                {e.text}
                              </label>
                            </div>
                          </>
                        ))}
                      {/* {checkedList.map(({ id, name}) => (
                          <label key={id}>
                            <input
                              type="radio"
                              name="options"
                              value={id}
                              // checked={checked}
                              onChange={(e) => changeList(id, e.target.checked)}
                            />
                            {name}
                          </label>
                        ))} */}
                    </Col>
                  </Row>
                </>
                // <ChoiceGroup
                //   selectedKey={this.state.question.conditionalValue}
                //   options={options}
                //   onChange={(e, i) => this.handleChoiceGroup(i)}
                //   label="Pick one"
                //   required={true}
                // />
              );
              break;

            case 6: //  Many of
              const checkOptions = q.options.split(";").map((s, i) => {
                return {
                  key: i.toString(),
                  text: s,
                };
              });

              setConditionsValue(
                <>
                  <Row className="mb-3">
                    <Col md={12}>
                      <label className="form-label">Pick at least one</label>
                      {checkOptions &&
                        checkOptions.map((e: any) => (
                          <>
                            <div className="form-check">
                              <input
                                {...register("conditionalValue")}
                                type="checkbox"
                                value={e.text}
                                className="form-check-input"
                                id={e.key}
                                key={e.key}
                              />
                              <label className="form-label" htmlFor={e.key}>
                                {e.text}
                              </label>
                            </div>
                          </>
                        ))}
                    </Col>
                  </Row>
                </>
              );
              // <CheckboxGroup
              //   value={this.state.question.conditionalValue}
              //   options={q.options}
              //   onChange={this.handleCheckboxGroup}
              //   label="Pick at least one"
              // />
              //condValue = <TextField name="condvalue" label={this.props.intl.formatMessage({ id: 'question.conditional.value' })} onChange={this.handleChange} value={this.state.question.conditionalValue} />;
              break;
          }
        }
      }
    }
  }, [
    selectedQuestion,
    isAnswerText,
    isAnswer,
    changeList,
    handleToggleChange,
    props.question?.conditional,
    questions,
    register,
    selectedSection,
  ]);

  const { getSurveyById, setSurvey, editSurvey, updateCompleteStatus, addCertificateAttachment, GetCertificateAttachmentFile } = useStoreActions(
    (actions) => ({
      getSurveyById: actions.surveyModel.getSurveyById,
      setSurvey: actions.surveyModel.setSurvey,
      editSurvey: actions.surveyModel.editSurvey,
      updateCompleteStatus: actions.surveyModel.updateCompleteStatus,
      addCertificateAttachment: actions.rfpTemplateModel.addCertificateAttachment,
      GetCertificateAttachmentFile: actions.surveyModel.GetCertificateAttachmentFile,
    })
  );

  const addCertificateAttachments = (data: any) => {
    let survey = window.location.pathname.split('/');
    let surveyId = survey[2];
    addCertificateAttachment({
      security: {
        APIKey: process.env.REACT_APP_API_KEY as string,
        User: user.attributes?.email as string,
      },
      surveyId: surveyId,
      name: data.Name,
      file: data.File,
    }).then((value: any) => {
      handleCloseDocument();
      (async function () {
        await GetCertificateAttachmentFile({
          security: {
            APIKey: process.env.REACT_APP_API_KEY as string,
            User: user.attributes?.email as string,
          },
          id: surveyId
        });
      })();
    })
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        contentClassName="mysa-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form
              className="edit-question-form"
              onSubmit={handleSubmit(onQuestionFormSubmit)}
            >
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">Type of Question</label>
                  <select
                    {...register("questionType", { required: true })}
                    className="form-select"
                    onChange={(event: any) => {
                      setQuestionType(event.target.value);
                    }}
                  >
                    <option value="0">Yes/No Question</option>
                    <option value="1">Text</option>
                    <option value="2">Number</option>
                    <option value="3">Currency</option>
                    <option value="4">Date</option>
                    <option value="5">One Of</option>
                    <option value="6">Many Of</option>
                    {/* <option value="7">Rating</option> */}
                    <option value="8">Read Document</option>
                    {/* <option value="9">Digitaly Sign</option> */}
                    <option value="10">Contact List</option>
                    <option value="11">User Defined List</option>
                    <option value="12">Provide an Image</option>
                    {/* <option value="13">Provide an Video</option> */}
                    <option value="14">Provide a Document</option>
                    <option value="15">Enter an address</option>
                    {/* <option value="16">Set the Survey Unit</option> */}
                  </select>
                  {errors.questionType && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
                <Col md={6}>
                  <label className="form-label">
                    Is the Question Mandatory
                  </label>
                  <Form.Check
                    type="switch"
                    id="mandatory"
                    label={mandatoryText}
                    onChange={handleToggleChange}
                    checked={mandatory}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <label className="form-label">Question to Ask</label>
                  <textarea
                    {...register("questionDescription", { required: true })}
                    className="form-control"
                    rows={3}
                  />
                  {errors.questionDescription && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              {opts}
              {validationSwitch}
              {validationOptions}
              <Row className="mb-3">
                <Col>
                  <label className="form-label">
                    Is this question conditional on the answer to another
                    question?
                  </label>
                  <Form.Check
                    type="switch"
                    id="conditional"
                    label={conditionText}
                    onChange={handleToggleChange}
                    checked={condition}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">Survey</label>
                  <select
                    {...register("questionConditionSurvey", {
                      required: condition,
                    })}
                    value={selectedSurvey?.id}
                    className="form-select"
                    disabled={!condition}
                    onChange={(e) => {
                      handleSurveySelect(e.target.value);
                      // setConditionalSurvey(
                      //   props.allSurveys.find(
                      //     (survey: any) => survey.id === e.target.value
                      //   )
                      // );
                      // setValue("questionConditionSection", "");
                      // setValue("questionConditionQuestion", "");
                    }}
                  >
                    <option value=""> Select Survey </option>
                    {props.allSurveys &&
                      props.allSurveys
                        .filter(
                          (x: any) =>
                            x.id === props.currentSurvey.id
                        )
                        .map((e: any) => (
                          <option value={e.id} key={e.id}>
                            {e.name}
                          </option>
                        ))}
                  </select>
                  {errors.questionConditionSurvey && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">Section</label>
                  <select
                    {...register("questionConditionSection", {
                      required: condition,
                    })}
                    value={selectedSection?.id}
                    className="form-select"
                    disabled={!condition}
                    onChange={(e) => {
                      handleSectionSelect(e.target.value);
                      // setConditionalSection(
                      //   conditionalSectionList?.find(
                      //     (section: any) => section.id === e.target.value
                      //   )
                      // );
                      // setValue("questionConditionQuestion", "");
                    }}
                  >
                    <option value="">Select Section</option>
                    {/* {conditionalSectionList &&
                      conditionalSectionList.map((e) => ( */}
                    {sections &&
                      sections.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </select>
                  {errors.questionConditionSection && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="form-label">Question</label>
                  <select
                    {...register("questionConditionQuestion", {
                      required: condition,
                    })}
                    value={selectedQuestion?.id}
                    className="form-select"
                    disabled={!condition}
                    onChange={(e) => {
                      handleQuestionSelect(e.target.value);
                      // setConditionalQuestion(
                      //   conditionalQuestionList?.find(
                      //     (question: any) => question.id === e.target.value
                      //   )
                      // );
                    }}
                  >
                    <option value="">Select Question</option>
                    {/* {conditionalQuestionList &&
                      conditionalQuestionList.map((e) => ( */}
                    {questions &&
                      questions.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.description}
                        </option>
                      ))}
                  </select>
                  {errors.questionConditionQuestion && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>{conditionValue}</Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="text-right">
                  <Button
                    id="approve-button"
                    className="create-btn"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    id="reject-button"
                    className="cancel-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>

      {/* add file upload document pop up */}
      <UploadDocumentPopup
        show={AddDocument}
        closeModal={handleCloseDocument}
        addCertificateAttachments={addCertificateAttachments}
      />
    </>
  );
};

export default QuestionAddEditPopUp;
