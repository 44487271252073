/* eslint-disable new-parens */
import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";

interface IRFPSurveysRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  buildingID?: string;
  clientID?: string;
  showExternal?: boolean;
}

export interface IRFPSurvey {
  id?: string;
  name?: string;
  programme?: string;
  contact?: string;
  phone?: string;
  email?: string;
}
interface IRFPSurveys {
  RFPSurveys: IRFPSurvey[];
}

interface IRFPSurveysAction {
  setAllRFPSurveys: Action<this, IRFPSurveys>;
}
interface IRFPSurveysThunk {
  getAllIRFPSurveys: Thunk<this, IRFPSurveysRequest>;
}

interface IRFPSurveysModel
  extends IRFPSurveys,
    IRFPSurveysAction,
    IRFPSurveysThunk {}

const rfpSurveysModel: IRFPSurveysModel = {
  RFPSurveys: [],
  setAllRFPSurveys: action((state, payload) => {
    state.RFPSurveys = payload.RFPSurveys;
  }),
  getAllIRFPSurveys: thunk((action, payload) => {
    axios
      .post("/certificate/search", payload)
      .then((value: any) => {
        let temIRFPSurveys: IRFPSurveys = {
          RFPSurveys: [],
        };
        value.data.certificates.forEach((element: any) => {
          temIRFPSurveys.RFPSurveys?.push({
            id: element.id,
            name: element.name,
            email: element?.contactInfo?.email,
            phone: element.phone,
            contact: element?.contactInfo?.name,
            programme: "",
          });
        });
        action.setAllRFPSurveys(temIRFPSurveys);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
      });
  }),
};

export default rfpSurveysModel;
