import React, { useEffect } from 'react';
import { formButton, mysaTableTheme, tabInside, tableStyles } from '../../../common/components-style';
import DataTable, { createTheme } from 'react-data-table-component';
import eyeIcon from '../../../images/table/eye.svg';
import searchIcon from '../../../images/table/search.svg';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { overlayTriggerDelay } from '../../../common/components-funtion';
import { useStoreActions, useStoreState } from '../../../store/models/model-hook';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const ClientList: React.FC = (): JSX.Element => {

    /////////////// navigate //////////////
    const navigate = useNavigate();

    ////////////// aws auth ///////////////////
    const { user } = useAuthenticator((context) => [context.user]);

    /////////////// global action management ////////////
    const { getAllClient } = useStoreActions((actions) => ({
        getAllClient: actions.clientModel.getAllClient
    }));

    /////////////// global state management ////////////
    const { Clients } = useStoreState((state) => ({
        Clients: state.clientModel.Clients
    }));

    useEffect(() => {
        (async function () {
            await getAllClient({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string
                },
                type: "Corporate Client",
                current: 1,
                pagesize: 10000000,
                sortfield: "",
                isFilterRequired: false
            });
        }());
    }, [getAllClient, user.attributes?.email]);

    //////////// column //////////////////////
    const columns = [
        {
            name: 'Name',
            selector: (row: any) => row.name,
        },
        {
            name: 'Programme ',
            selector: (row: any) => row.programme,
        },
        {
            name: 'Contact',
            selector: (row: any) => row.contact,
        },
        {
            name: 'Phone Number',
            selector: (row: any) => row.phone,
        },
        {
            name: 'Email Address',
            selector: (row: any) => row.email,
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => {

                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                    >
                        <img className='iconSizes' src={eyeIcon} alt="" onClick={()=>{
                            navigate(`/client-management/${row.id}`);
                        }}/>
                    </OverlayTrigger>
                </>)
            },
        },
    ];

    return (
        <>
            <div style={tabInside}>
                <Row style={{ marginTop: '3%' }}>
                    <Col md={3}>
                        <Form>
                            <Form.Control />
                        </Form>
                    </Col>
                    <Col md={2}>
                        <Button type="submit" id='approve-button' style={formButton}><img src={searchIcon} alt="searchIcon" style={{ height: 20 }} /> Search</Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={Clients}
                            pagination
                            striped={true}
                            customStyles={tableStyles}
                            theme='mysaTable'
                            fixedHeader={true}
                            noDataComponent={'Updating records'}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );

};

export default ClientList;