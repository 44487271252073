/* eslint-disable import/no-anonymous-default-export */
// hooks.js
import { createTypedHooks } from "easy-peasy";

import clientModel from "./client/client-model";
import rfpSurveysModel from "./client/rfp-surveys-model";
import rfpTemplateModel from "./rfp/rfp-template-model";
import propertyModel from "./properties/property-model";
import brandSurveyModel from "./client/brand-survey/brand-survey-model";
import surveyModel from "./survey/survey-model";

//////// model interface /////////
export interface IStoreModel {
  clientModel: typeof clientModel;
  rfpSurveysModel: typeof rfpSurveysModel;
  propertyModel: typeof propertyModel;
  rfpTemplateModel: typeof rfpTemplateModel;
  surveyModel: typeof surveyModel;
  brandSurveyModel: typeof brandSurveyModel;
}

const { useStoreActions, useStoreState, useStoreDispatch, useStore } =
  createTypedHooks<IStoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };
