import React, { useEffect } from "react";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap";
import {
  useStoreActions,
  useStoreState,
} from "../../../store/models/model-hook";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbPage from "../../../common/bread-crumb-page";
import {
  navContentStyle,
  navStyle,
  searchButton,
  tabDivStyle,
} from "../../../common/components-style";
import Helper from "../../../utils/helper";
import BrandClientDetailsSideMenu from "./brand-client-details-side-menu";
import { SpinLoader } from "../../../utils/spin-loader";

const ClientDetails: React.FC = (): JSX.Element => {
  let { id } = useParams(); 
  const { user } = useAuthenticator((context) => [context.user]); 
  const navigate = useNavigate();
  const { getClientById } = useStoreActions((actions) => ({
    getClientById: actions.clientModel.getClientById,
  }));
  const { Client, IsLoading } = useStoreState((state) => ({
    Client: state.clientModel.Client,
    IsLoading: state.clientModel.IsLoading,
  }));

  useEffect(() => {
    (async function () {
      await getClientById({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
        id: id,
      });
    })();
  }, [getClientById, id, user.attributes?.email]);
  return (
    <>
      <div style={tabDivStyle}>
        <Row>
          <Col sm={2}>
            <Nav
              variant="pills"
              className="flex-column sec secondry-menu "
              style={navStyle}
            >
              <BrandClientDetailsSideMenu id={id} />
            </Nav>
          </Col>
          <Col sm={10}>
            <SpinLoader isLoading={IsLoading} />
            <div style={navContentStyle}>
              <BreadCrumbPage
                selectedBrandCrumb="brandManagement"
                activateName={Client?.name}
              />
              <Row style={{ marginTop: "3%" }}>
                <Col md={2}></Col>
                <Col md={8}>
                  <Container>
                    <div className="jumbotron">
                      <Row>
                        <Col md={6} style={{ textAlign: "right" }}>
                          <Form.Label>Client Name</Form.Label>
                        </Col>
                        <Col md={6} style={{ textAlign: "left" }}>
                          <p className="lead">{Client?.name}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} style={{ textAlign: "right" }}>
                          <Form.Label>Email Address</Form.Label>
                        </Col>
                        <Col md={6} style={{ textAlign: "left" }}>
                          <p className="lead">{Client?.email}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} style={{ textAlign: "right" }}>
                          <Form.Label>Contact</Form.Label>
                        </Col>
                        <Col md={6} style={{ textAlign: "left" }}>
                          <p className="lead">{Client?.contact}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} style={{ textAlign: "right" }}>
                          <Form.Label>Phone Number</Form.Label>
                        </Col>
                        <Col md={6} style={{ textAlign: "left" }}>
                          <p className="lead">
                            {new Helper().formatePhoneNumber(
                              Client?.phone ?? ""
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          <Button
                            type="button"
                            id="reject-button"
                            style={searchButton}
                            onClick={() => {
                              navigate(`/brand-management`);
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </Col>
                <Col md={2}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClientDetails;
