import React , {useEffect} from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { formButton, mysaTableTheme, tabInside, tableStyles } from '../../../common/components-style';
import DataTable, { createTheme } from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useStoreActions, useStoreState } from '../../../store/models/model-hook';
import { overlayTriggerDelay } from '../../../common/components-funtion';
import eyeIcon from '../../../images/table/eye.svg';
import BreadCrumbPage from '../../../common/bread-crumb-page';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const RfpSurveysList: React.FC = (): JSX.Element => {

        //////////////// handle url /////////////
        let { id } = useParams();

        /////////////// navigate //////////////
        const navigate = useNavigate();

        ////////////// aws auth ///////////////////
        const { user } = useAuthenticator((context) => [context.user]);
    
        /////////////// global action management ////////////
        const { getAllIRFPSurveys } = useStoreActions((actions) => ({
            getAllIRFPSurveys: actions.rfpSurveysModel.getAllIRFPSurveys
        }));
    
        /////////////// global state management ////////////
        const { RFPSurveys, Client } = useStoreState((state) => ({
            RFPSurveys: state.rfpSurveysModel.RFPSurveys,
            Client: state.clientModel.Client
        }));
    
        useEffect(() => {
            (async function () {
                await getAllIRFPSurveys({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string
                    },
                    buildingID: "",
                    clientID: id,
                    showExternal: false,
                });
            }());
        }, [getAllIRFPSurveys, id, user.attributes?.email]);
    
        //////////// column //////////////////////
        const columns = [
            {
                name: 'Name',
                selector: (row: any) => row.name,
            },
            {
                name: 'Programme ',
                selector: (row: any) => row.programme,
            },
            {
                name: 'Contact',
                selector: (row: any) => row.contact,
            },
            {
                name: 'Phone Number',
                selector: (row: any) => row.phone,
            },
            {
                name: 'Email Address',
                selector: (row: any) => row.email,
            },
            {
                name: 'Action',
                button: true,
                cell: (row: any) => {
    
                    return (<>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
                        >
                            <img className='iconSizes' src={eyeIcon} alt="" onClick={()=>{
                                navigate(`/client-management/${row.id}`);
                            }}/>
                        </OverlayTrigger>
                    </>)
                },
            },
        ];

    return (
        <>
            <BreadCrumbPage selectedBrandCrumb="clientManagement" activateName={Client?.name}/>
            <div style={tabInside}>
                {/* <Row style={{ marginTop: '3%' }}>
                    <Col md={10}></Col>
                    <Col md={2}>
                        <Button type="submit" id='approve-button' style={formButton}> Create New Survey</Button>
                    </Col>
                </Row> */}
                <Row style={{ marginTop: '1%' }}>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={RFPSurveys}
                            pagination
                            striped={true}
                            customStyles={tableStyles}
                            theme='mysaTable'
                            fixedHeader={true}
                            noDataComponent={'Updating records'}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default RfpSurveysList;