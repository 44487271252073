import ReactDOM from 'react-dom/client';
import './index.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from 'easy-peasy';
import store from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const StoreProviderOverride = StoreProvider as any;

root.render(
  <StoreProviderOverride store={store}>
    <App />
  </StoreProviderOverride>
);

reportWebVitals();
