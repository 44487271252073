import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../common/components-style';
import ClientDetails from './client-details';
import RfpSurveysList from './rfp-surveys-list';

const ClientDetailsSideMenu: React.FC = (): JSX.Element => {
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="detail" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu " style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="detail">Detail</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="clientContracts">Client Contracts</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="rfpSurveysList">RFP Surveys</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="detail" >
                                    <div style={navContentStyle}>
                                        <ClientDetails />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="clientContracts">
                                    <div style={navContentStyle}>

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="rfpSurveysList">
                                    <div style={navContentStyle}>
                                            <RfpSurveysList/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
};

export default ClientDetailsSideMenu;