import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, NavLink } from "react-router-dom";
import { overlayTriggerDelay } from "../common/components-funtion";
import {
  iconDivStyle,
  sideMenuStyle,
  toolTipStyle,
} from "../common/components-style";
import homeActivate from "../images/nav-icon/activate/home.svg";
import homeDeactivate from "../images/nav-icon/deactivate/home.svg";
import mysaSureActivate from "../images/nav-icon/activate/mysa-sure.svg";
import mysaSureDeactivate from "../images/nav-icon/deactivate/mysa-sure.svg";
import brandActivate from "../images/nav-icon/activate/brand.svg";
import brandDeactivate from "../images/nav-icon/deactivate/brand.svg";

const SideMenu: React.FC = (): JSX.Element => {
  const location = useLocation();
  return (
    // <Nav defaultActiveKey="/home" className="flex-column">
    //     <Nav.Link href="/home">Active</Nav.Link>
    //     <Nav.Link eventKey="link-1">Link</Nav.Link>
    //     <Nav.Link eventKey="link-2">Link</Nav.Link>
    //     <Nav.Link eventKey="disabled" disabled>
    //         Disabled
    //     </Nav.Link>
    // </Nav>
    <div style={sideMenuStyle}>
      <NavLink to="/">
        {location.pathname === "/" ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Home
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={homeActivate} alt="home" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Home
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={homeDeactivate} alt="home" />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
      <NavLink to="/digital-audit">
        {location.pathname === "/digital-audit" ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Digital Audit
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={mysaSureActivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Digital Audit
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img src={mysaSureDeactivate} alt="Digital Audit" />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
      <NavLink to="/brand-management">
        {location.pathname.includes ("/brand-management") ||
        location.pathname.includes("/survey-management") ||
        location.pathname.includes("/rfp-management") ||
        location.pathname.includes("/contract-management") ? (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Myo Management
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img
                src={brandActivate}
                alt="Myo Management"
                className="developer-active"
              />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={overlayTriggerDelay}
            overlay={
              <Tooltip id="tooltip-right" style={toolTipStyle}>
                Myo Management
              </Tooltip>
            }
          >
            <div style={iconDivStyle}>
              <img
                src={brandDeactivate}
                alt="Myo Management"
                className="developer-deactive"
              />
            </div>
          </OverlayTrigger>
        )}
      </NavLink>
      {/* <NavLink
                to="/client-management">
                {
                    location.pathname === '/client-management' ?
                        <OverlayTrigger
                            placement="right"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Client Management</Tooltip>}
                        >
                            <div style={iconDivStyle}><img src={clientActivate} alt="Client Management" /></div>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                            placement="right"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Client Management</Tooltip>}
                        >
                            <div style={iconDivStyle}><img src={clientDeactivate} alt="Client Management" /></div>
                        </OverlayTrigger>
                }
            </NavLink> */}
    </div>
  );
};

export default SideMenu;
