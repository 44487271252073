import React, { useCallback, useEffect } from "react";
import {
    mysaTableTheme,
    pageHeader,
    tabInside,
    tableStyles,
} from "../../../common/components-style";
import DataTable, { createTheme } from "react-data-table-component";
import eyeIcon from "../../../images/table/eye.svg";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { overlayTriggerDelay } from "../../../common/components-funtion";
import {
    useStoreActions,
    useStoreState,
} from "../../../store/models/model-hook";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { SpinLoader } from "../../../utils/spin-loader";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../utils/constants";
import useState from "react-usestateref";
import { SetTableOptions } from "../../../shared/set-table-options";
createTheme("mysaTable", mysaTableTheme, "light");

const BrandClientList: React.FC = (): JSX.Element => {
    const brandClientType: string = "Brand Client";
    const [, setSearchKey, searchKeyRef] = useState<String>("");
    const navigate = useNavigate(); //navigate
    const { user } = useAuthenticator((context) => [context.user]); //aws auth
    const { getAllClient } = useStoreActions((actions) => ({
        getAllClient: actions.clientModel.getAllClient,
    }));
    const { Clients, TotalRows, ClientNames, IsLoading } = useStoreState(
        (state) => ({
            Clients: state.clientModel.Clients,
            TotalRows: state.clientModel.TotalRows,
            ClientNames: state.clientModel.ClientNames,
            IsLoading: state.clientModel.IsLoading,
        })
    );

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
    const [brandStatus, setBrandStatus] = useState('live_brand');
    const [perPage, setPerPage] = useState(PAGE_SIZE);

    const getRecords = useCallback(
        (page: number, size = perPage, isFilterRequired: boolean = false) => {
            setLoading(true);
            (async function () {
                await getAllClient({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    type: brandClientType,
                    current: page,
                    pagesize: size,
                    sortfield: "",
                    Email: "",
                    Name: searchKeyRef?.current?.replace("/s/g", "") as string,
                    isFilterRequired: isFilterRequired,
                    filterBrand: brandStatus,
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllClient, perPage, searchKeyRef, user.attributes?.email, brandStatus]
    );

    useEffect(() => {
        getRecords(CURRENT_PAGE, perPage, true);
    }, [perPage, getRecords]);

    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
        },
        {
            name: "Contact",
            selector: (row: any) => row.contact,
        },
        {
            name: "Phone Number",
            selector: (row: any) => row.phone,
        },
        {
            name: "Email Address",
            selector: (row: any) => row.email,
        },
        {
            name: "Action",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/brand-management/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page);
        },
        [getRecords]
    );

    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={pageHeader}>
                <h3>Brand Management</h3>
            </div>
            <div style={tabInside}>
                <Form>
                    <Row style={{ marginTop: "3%" }}>
                        <Col md={3}>
                            <Typeahead
                                id="brand-list-filter"
                                labelKey="name"
                                clearButton
                                onChange={(options: any) => {
                                    if (options && options.length) {
                                        let brand = options[0];
                                        setSearchKey(brand);
                                    } else {
                                        setSearchKey("");
                                    }

                                    setCurrentPage(1);
                                    getRecords(1);
                                }}
                                options={ClientNames}
                                placeholder="Select a Brand"
                            />
                        </Col>
                        <Col md={3}>
                            <select className="form-select"
                                onChange={((event: any) => {
                                    setBrandStatus(event.target.value);
                                    setCurrentPage(1);
                                })}
                            >
                                <option value="live_brand">Live Brands</option>
                                <option value="archive_brand">Archived Brands</option>
                            </select>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginTop: "1%" }}>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={Clients}
                            striped={true}
                            customStyles={tableStyles}
                            theme="mysaTable"
                            fixedHeader={true}
                            noDataComponent={<SetTableOptions loading={loading} />}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={loading}
                            progressComponent={<SpinLoader />}
                            pagination
                            paginationServer
                            paginationTotalRows={TotalRows}
                            paginationDefaultPage={currentPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BrandClientList;
