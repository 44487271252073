import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BrandClientSideMenu: React.FC = (): JSX.Element => {
  return (
    <>
      <div style={{ marginTop: "0%" }}>
        <Nav>
          <NavLink className="nav-link" to="/brand-management">
            Brand Management
          </NavLink>
        </Nav>
        <Nav>
          <NavLink className="nav-link" to="/rfp-management">
            RFP Management
          </NavLink>
        </Nav>
        <Nav>
          <NavLink className="nav-link" to="/survey-management">
            Survey Management
          </NavLink>
        </Nav>
        <Nav>
          <NavLink className="nav-link" to="/contract-management">
            Contract Management
          </NavLink>
        </Nav>
      </div>
      {/* <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="brands" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column" style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    {/* <Nav.Item><Nav.Link eventKey="brands">Brands</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="contactCreate">Contact Create</Nav.Link></Nav.Item> ***

                                    <Nav.Item>
                                        <Nav.Link eventKey="brands">
                                            Brand Management
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="rfp">
                                            RFP Management
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="survey">
                                            Survey Management
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="contactCreate">
                                            Contract Management
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="brands" >
                                    <div style={navContentStyle}>
                                        <BrandClientList />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="rfp">
                                    <div style={navContentStyle}>
                                        <RFPClientList />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="survey" >
                                    <div style={navContentStyle}>
                                        {
                                            enableSurveyDetails ? 
                                                <SurveyDetails surveyId ={enableSurveyDetails} backToSurveyList = {editSurvey}/> : 
                                                <SurveyList editSurvey = {editSurvey}/>
                                        }                                        
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="contactCreate">
                                    <div style={navContentStyle}>

                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div> */}
    </>
  );
};

export default BrandClientSideMenu;
