import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BrandPropertySideMenu: React.FC<any> = (props): JSX.Element => {
  return (
    <>
      <div style={{ marginTop: "0%" }}>
        <Nav>
          <NavLink
            className="nav-link"
            to={`/brand-management/property/${props.clientId}/${props.propertyId}`}
          >
            Detail
          </NavLink>
        </Nav>
        <Nav>
          <NavLink
            className="nav-link"
            to={`/brand-management/property-surveys/${props.clientId}/${props.propertyId}`}
          >
            Property Surveys
          </NavLink>
        </Nav>
      </div>
    </>
  );
};

export default BrandPropertySideMenu;
