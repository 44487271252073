import { Col, Nav, Row } from "react-bootstrap";
import { navContentStyle, navStyle, tabDivStyle } from "../../common/components-style";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";

const ContractHome: React.FC = () : JSX.Element => {
    return(
        <div style={tabDivStyle}>
            <Row >
                <Col sm={2}>
                    <Nav variant="pills" className="flex-column secondry-menu " style={navStyle}>
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <div style={navContentStyle}>
                        
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default ContractHome;