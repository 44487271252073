import { Store } from 'react-notifications-component';

class NotificationAlert {

    public createNotification(type: any, message: any, title: any) {
        Store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }
}

export default NotificationAlert;