import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Upload from "rc-upload";
import uploadSvg from "../../../images/upload.svg";
import { formButton } from "../../../common/components-style";

const BrandContractsCreate: React.FC = (): JSX.Element => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <Row style={{ marginTop: "3%" }}>
        <Col md={2}></Col>
        <Col md={8}>
          <Container>
            <div className="jumbotron">
              <Form>
                <Row>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      <span className="requiredMark">*</span> Client Name
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Control />
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      <span className="requiredMark">*</span> Contract Name
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Control />
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      <span className="requiredMark">*</span> Date Signed
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <p className="lead">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date: Date) => {
                          setStartDate(date);
                        }}
                      />
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      <span className="requiredMark">*</span> Date Expires
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <p className="lead">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date: Date) => {
                          setStartDate(date);
                        }}
                      />
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      <span className="requiredMark">*</span> Upload
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <p className="lead">
                      <Upload>
                        <img src={uploadSvg} alt="upload" />
                      </Upload>
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6} style={{ textAlign: "right" }}></Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Button type="submit" id="edit-button" style={formButton}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Container>
        </Col>
        <Col md={2}></Col>
      </Row>
    </>
  );
};

export default BrandContractsCreate;
