import Spinner from "react-bootstrap/Spinner";
export const SpinLoader: React.FC<any> = (props): JSX.Element => {
  return (
    <div className={`spinner-bg ${props.isLoading ? "" : "d-none"}`}>
      <div className="spinner-div">
        <Spinner animation="border" className="spinner" />
      </div>
    </div>
  );
};
