import React from "react";

const NoDataFound: React.FC<{ loading: boolean }> = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <p style={{ color: 'grey' }}>No Data Found</p>
      </div>
    );
};

export default NoDataFound;