import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./survey.css";

const SectionCreatePopUp: React.FC<any> = (props): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const handleClose = () => {
        props.closeModal();
    };

    const onSectionFormSubmit = (section: any) => {
        props.manageSection(section);
    };

    const resetForm = useCallback(
        async (result: any) => {
            reset(result);
        },
        [reset]
    );

    useEffect(() => {
        if (props.show) {
            let result;
            result = {
                SectionName: "",
                SectionDescription: "",
            };

            resetForm(result);
        }
    }, [props.show, resetForm]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                contentClassName="mysa-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <p>
                            To create a new section please provide both a name and a
                            description of the section.
                        </p>
                        <form
                            className="create-section-form"
                            onSubmit={handleSubmit(onSectionFormSubmit)}
                        >
                            <Row className="mb-3">
                                <Col md={12}>
                                    <label className="form-label">Section Name </label>
                                    <input
                                        {...register("SectionName", { required: true })}
                                        className="form-control"
                                        autoFocus
                                        autoComplete="off"
                                    />
                                    {errors.SectionName && (
                                        <span className="error">This field is required</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <label className="form-label">Section Description</label>
                                    <textarea
                                        {...register("SectionDescription")}
                                        className="form-control"
                                        rows={3}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={12} className="text-right">
                                    <Button
                                        id="approve-button"
                                        className="create-btn"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        id="reject-button"
                                        className="cancel-btn"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SectionCreatePopUp;
