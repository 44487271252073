import React, { useCallback, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./survey.css";

const UploadDocumentPopUp: React.FC<any> = (props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleClose = () => {
    props.closeModal();
  };

  const resetForm = useCallback(
    async (result: any) => {
      reset(result);
    },
    [reset]
  );

  useEffect(() => {
    if (props.show) {
      let result;
      result = {
        Name: "",
      };

      resetForm(result);
    }
  }, [props.show, resetForm]);

  const onUploadDocument = (data: any) => {
    props.addCertificateAttachments(data);
  }
  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="mysa-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form
              className="edit-question-form"
              onSubmit={handleSubmit(onUploadDocument)}
            >
              <Row className="mb-3">
                <Col md={6}>
                  <input accept='application/pdf' 
                  {...register("File",{ required: true })}
                    type="file"
                    id="upload-button"
                  /><br/>
                   {errors.File && (
                    <span className="error">This field is required</span>
                  )}
                </Col></Row>
              <Row className="mb-3">
                <Col md={6}>
                  <label className="form-label">File Name </label>
                  <input
                    {...register("Name", { required: true })}
                    className="form-control"
                    autoFocus
                    autoComplete="off"
                  />
                   {errors.Name && (
                    <span className="error">This field is required</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <button type="submit" id="approve-button" className="create-btn">Save</button>
                  <button type="button" id="reject-button" className="cancel-btn" onClick={handleClose}>Cancel</button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadDocumentPopUp;
