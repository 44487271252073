import React from 'react';
import ClientSideMenu from './client-side-menu';

const ClientHome: React.FC = (): JSX.Element => {
    return (
        <>
            <ClientSideMenu />
        </>
    )
};

export default ClientHome;