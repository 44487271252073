class Helper extends Function {
  formatePhoneNumber(value: string) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    // if (phoneNumberLength < 7)
    //     return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
      2,
      6
    )} ${phoneNumber.slice(6)}`;
  }

  getSurveyTypeName(value: string | undefined) {
    if (value == "BRAND_SURVEY") {
      return "Brand";
    } else if (value == "PROPERTY_SURVEY") {
      return "Property";
    } else {
      return "";
    }
  }
}

export default Helper;
