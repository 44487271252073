import { withAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { awsExports } from "./shared/aws-exports";
import { BrowserRouter } from "react-router-dom";
import { Header } from "./shared/aws-auth-header";
import AppHeader from "./shared/app-header";
import SideMenu from "./shared/side-menu";
import Router from "./shared/router";
import { ReactNotifications } from "react-notifications-component";

/* aws style */
import "./aws-style.scss";
import ScrollToTop from "./utils/scroll-top";

Amplify.configure(awsExports);

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <ReactNotifications />
            <AppHeader />
            <div style={{ marginTop: "70px" }}>
                <SideMenu />
                <Router />
            </div>
        </BrowserRouter>
    );
}

export default withAuthenticator(App, {
    components: {
        Header,
    },
});
