import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";

const SurveyCreate: React.FC<any> = (props): JSX.Element => {
    const [showClientError, setShowClientError] = useState<String>("none");
    const [clientId, setClientId] = useState<String>("");
    const [corporateClient, setCorporateClient] = useState<String>("");
    const [programme, setProgramme] = useState<String>("");
    const [rfpDate, setRFPDate] = useState<String>("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const handleClose = () => {
        props.closeModal();
    };

    const { RFPTemplateForSurvey, TotalRows } = useStoreState((state) => ({
        RFPTemplateForSurvey: state.rfpTemplateModel.RFPTemplateForSurvey,
        TotalRows: state.surveyModel.TotalRows,
    }));

    const { getAllRFPTemplatesForSurvey } = useStoreActions((actions) => ({
        getAllRFPTemplatesForSurvey: actions.rfpTemplateModel.getAllRFPTemplatesForSurvey,
    }));

    const [selected, setSelected] = useState<Option[]>();
    useEffect(() => {
        const selectedRfp = props.isCopy
            ? RFPTemplateForSurvey.find(element => 
                element.clientName === props.editSurvey?.clientName && element.programme === props.editSurvey?.programme
            )
            : RFPTemplateForSurvey.find(element => 
                element.clientName === 'MYSA' && element.programme === 'Global'
            );

        const defaultRfpDate = moment(new Date()).format("DD/MM/YYYY");

        setSelected([
            {
                clientName: selectedRfp?.clientName ?? "",
                id: selectedRfp?.id ?? "",
                name: selectedRfp?.name ?? "",
                programme: selectedRfp?.programme ?? "",
                rfpDate: defaultRfpDate,
                showName: selectedRfp?.name ?? "" + selectedRfp?.rfpDate ?? "",
            },
        ]);
        
        setRFPDate(defaultRfpDate);
        setCorporateClient(selectedRfp?.clientName?? "");
        setClientId(selectedRfp?.id?? "");
        setProgramme(selectedRfp?.programme ?? "");
    }, [props.isCopy, props.editSurvey, RFPTemplateForSurvey]);

    useEffect(() => {
        if (props.isCreate) {
            setSelected([]);
        }
    }, [props.isCreate]);

    // const handleOnSelect = (item: any) => {
    //     if (item) {
    //         setClientId(item.id);
    //         setCorporateClient(item.clientName);
    //         setProgramme(item.programme);
    //         setRFPDate(item.rfpDate);
    //         setShowClientError("none");
    //     } else {
    //         setClientId("");
    //         setCorporateClient("");
    //         setProgramme("");
    //         setRFPDate("");
    //     }
    // };

    useEffect(() => {
        (async function () {
            await getAllRFPTemplatesForSurvey({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: props.email as string,
                },
                clientID: props.clientId,
                current: 1,
                pagesize: 10000000,
                sortfield: "",
                isFilterRequired: false,
            });
        })();
    }, [getAllRFPTemplatesForSurvey, props.email, props.clientId]);

    const resetForm = useCallback(
        async (result: any) => {
            reset(result);
            setShowClientError("none");
        },
        [reset]
    );

    useEffect(() => {
        if (props.show) {
            let result;
            if (props.isCopy) {
                result = {
                    SurveyName: "",
                    Description: props.editSurvey.description,
                    SurveyType: props.editSurvey.typeOfSurveyValue,
                };

                // handleOnSelect(props.editSurvey);
            } else {
                result = {
                    SurveyName: "",
                    Description: "",
                    SurveyType: "",
                };
            }

            resetForm(result);
        }
    }, [props.show, setSelected, props.editSurvey, props.isCopy, resetForm]);

    const onSubmit = (d: any) => {
        if (!clientId) {
            setShowClientError("block");
            return;
        }

        d.clientId = clientId;
        d.rfpDate = rfpDate;
        d.programme = programme;
        d.corporateClient = corporateClient;
        d.rfpTemplateId = clientId;
        
        setClientId("");
        setCorporateClient("");
        setProgramme("");
        setRFPDate("");
        
        props.createRecord(d);
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
                contentClassName="mysa-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Survey</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-new">
                    <Container>
                        <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3 text-right">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Survey Name </label>
                                </Col>
                                <Col xs={8} md={8}>
                                    <input
                                        {...register("SurveyName", { required: true })}
                                        className="form-control"
                                        autoFocus
                                    />
                                    {errors.SurveyName && (
                                        <span className="error">This field is required</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3 text-right">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Client</label>
                                </Col>
                                <Col xs={8} md={8}>
                                    <Typeahead
                                        id="basic-example"
                                        labelKey="showName"
                                        // onChange={(options: any) => {
                                        //     setSelected(options);
                                        //     handleOnSelect(options[0]);
                                        // }}
                                        options={RFPTemplateForSurvey}
                                        placeholder="Choose a Client"
                                        defaultSelected={selected}
                                        disabled={true}
                                    />
                                    <span
                                        className="error"
                                        style={{ display: showClientError as string }}
                                    >
                                        This field is required
                                    </span>
                                </Col>
                            </Row>
                            <Row className="mb-3 text-right">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Description</label>
                                </Col>
                                <Col xs={8} md={8}>
                                    <textarea
                                        {...register("Description")}
                                        className="form-control"
                                        rows={3}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3 text-right">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Type of Survey</label>
                                </Col>
                                <Col xs={8} md={8}>
                                    <select
                                        {...register("SurveyType", { required: true })}
                                        className="form-select"
                                    >
                                        <option defaultValue="" value="">
                                            Select
                                        </option>
                                        <option value="BRAND_SURVEY">Brand</option>
                                        <option value="PROPERTY_SURVEY">Property</option>
                                    </select>
                                    {errors.SurveyType && (
                                        <span className="error">This field is required</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="text-right offset-4 col-md-8 col-8">
                                    <Button
                                        id="approve-button"
                                        className="create-btn"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        id="reject-button"
                                        className="cancel-btn"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SurveyCreate;
