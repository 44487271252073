import { action, thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";
import moment from "moment";
import ISurveyThunk from "./ISurveyThunk";
import ISurveyAction from "./ISurveyAction";
import ISurveys from "./ISurveys";
import { DropdownButton } from "react-bootstrap";

interface ISurveyModel extends ISurveys, ISurveyAction, ISurveyThunk { }
const surveyModel: ISurveyModel = {
  Surveys: [],
  SurveyNames: [],
  ClientNames: [],
  Survey: {
    name: "",
    description: "",
    validFor: 0,
    completionMethod: 0,
    sections: [],
    certificateCount: 0,
    templateCount: 0,
    canDelete: false,
  },
  TotalRows: 0,
  IsLoading: false,
  Attachments: [],

  getAllSurveys: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/survey/search", payload)
      .then((value: any) => {
        let tempRSurveys: ISurveys = {
          Surveys: [],
          Survey: {
            name: "",
            description: "",
            validFor: 0,
            completionMethod: 0,
            sections: [],
            certificateCount: 0,
            templateCount: 0,
            canDelete: false,
          },
          SurveyNames: [],
          ClientNames: [],
          TotalRows: 0,
          IsLoading: false,
          Attachments: [],
        };
        value.data.surveys.forEach((element: any) => {
          tempRSurveys.Surveys?.push({
            id: element.id,
            programme: element.programme,
            clientName: element.corporate_client,
            releaseDate: element.release_date !==null? moment(element.release_date).format("DD/MM/yyyy") : "",
            rfpDate:
              element.rfp_date == null
                ? element.programme
                : moment(element.rfp_date).format("DD/MM/yyyy"),
            rfp_EndDate:
                element.rfp_EndDate == null
                  ? ''
                  : moment(element.rfp_EndDate).format("DD/MM/yyyy"),
            rfpStatus: element.rfpStatus,
            surveyName: element.name,
            description: element.description,
            typeOfSurvey:
              element.survey_type === "PROPERTY_SURVEY" ? "Property" : "Brand",
            typeOfSurveyValue: element.survey_type,
            isArchived: element.isArchived,
            isApproved: element.isApproved,
            isAllSectionCompleted: element.isAllSectionCompleted,
            status: element.status,
            RFPTemplateId: element.rfpTemplateId,
          });
        });
        
        action.setAllSurveys(tempRSurveys);
        action.setTotalRows(value.data.totalCount);
        if (payload.isFilterRequired) {
          action.setAllClientNamesForFilter(value.data.clientNames);
        }
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  setAllSurveys: action((state, payload) => {
    state.Surveys = payload.Surveys;
  }),

  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),

  setAllClientNamesForFilter: action((state, payload) => {
    state.ClientNames = payload;
  }),

  getAllSurveyForDropdown: thunk((action, payload) => {
    axios
      .post("/survey/searchName", payload)
      .then((value: any) => {
        action.setSurveyNames(value.data.surveys);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
      });
  }),

  setSurveyNames: action((state, payload) => {
    state.SurveyNames = payload;
  }),

  getSurveyById: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post(`/survey/EditSurvey`, payload)
      .then((value: any) => {
        let tempSurvey: ISurveys = {
          Surveys: [],
          Survey: {
            name: "",
            description: "",
            validFor: 0,
            completionMethod: 0,
            sections: [],
            certificateCount: 0,
            templateCount: 0,
            canDelete: false,
          },
          SurveyNames: [],
          ClientNames: [],
          TotalRows: 0,
          IsLoading: false,
          Attachments: [],
        };
        if (value.data.survey) {
          tempSurvey.Surveys = value.data.allSurveys;
          tempSurvey.Survey = {
            id: value.data.survey.id,
            name: value.data.survey.name,
            corporate_client: value.data.survey?.corporate_client,
            description: value.data.survey.description,
            rfp_date: value.data.survey.rfp_date,
            rfp_EndDate: value.data.survey.rfp_EndDate,
            rfpStatus: value.data.survey.rfpStatus,
            programme: value.data.survey.programme,
            validFor: value.data.survey.validFor,
            useExpectedCompletionPeriod:
              value.data.survey.useExpectedCompletionPeriod,
            expectedCompletionPeriod:
              value.data.survey.expectedCompletionPeriod,
            completionMethod: value.data.survey.completionMethod,
            sections: value.data.survey.sections,
            autoComplete: value.data.survey.autoComplete,
            autoCompleteSurveyID: value.data.survey.autoCompleteSurveyID,
            certificateCount: value.data.survey.certificateCount,
            templateCount: value.data.survey.templateCount,
            canDelete: value.data.survey.canDelete,
            survey_type: value.data.survey.survey_type,
            clientId: value.data.survey.clientID,
            RFPTemplateId: value.data.survey.rfpTemplateId,
            status: value.data.survey.status,
            isApproved: value.data.survey.isApproved
          };
        }
        // if (value.data.attachments) {
        //   tempSurvey.Attachments.push(value.data.attachments);
        // }
        // action.setAttachments(tempSurvey.Attachments);
        action.setSurvey(tempSurvey);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  setAttachments: action((state, payload) => {
    state.Attachments = payload;
  }),

  setSurvey: action((state, payload) => {
    state.Survey = payload.Survey;
    state.Surveys = payload.Surveys;
  }),

  createSurvey: thunk((action, payload) => {
    axios
      .post("/survey/create", payload)
      .then((value: any) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "success",
          "Survey created successfully.",
          "Survey"
        );
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
      });
  }),

  editSurvey: thunk(async (action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/survey/save", payload)
      .then((value: any) => {
     // const notification = new NotificationAlert();
        // notification.createNotification(
        //   "success",
        //   "Survey saved successfully.",
        //   "Survey"
        // );
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),

  archiveSurvey: thunk((action, payload) => {
    axios
      .post("/survey/archive", payload)
      .then((value: any) => {
        if (value.data.status.errorCode === 0) {
          const notification = new NotificationAlert();
          notification.createNotification(
            "success",
            "Survey archived successfully.",
            "Survey"
          );
        } else {
          const notification = new NotificationAlert();
          notification.createNotification(
            "danger",
            value.data.status.message,
            "Something Went wrong."
          );
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
      });
  }),

  updateCompleteStatus: thunk((action, payload) => {
    axios
      .post("/survey/UpdateCompleteStatus", payload)
      .then((value: any) => {
        if (value.data.status.errorCode === 0) {
          const notification = new NotificationAlert();
          notification.createNotification(
            "success",
            "Survey completed successfully.",
            "Survey"
          );
        } else {
          const notification = new NotificationAlert();
          notification.createNotification(
            "danger",
            value.data.status.message,
            "Something Went wrong."
          );
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  updateApprovedStatus: thunk((action, payload) => {
    axios
      .post("/survey/UpdateApprovedStatus", payload)
      .then((value: any) => {
        if (value.data.status.errorCode === 0) {
          const notification = new NotificationAlert();
          notification.createNotification(
            "success",
            "Survey Approved successfully.",
            "Survey"
          );
        } else {
          const notification = new NotificationAlert();
          notification.createNotification(
            "danger",
            value.data.status.message,
            "Something Went wrong."
          );
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),

  GetCertificateAttachmentFile: thunk((action, payload) => {
    axios
      .post("certificate/template/GetAttachmentFile", payload)
      .then((value: any) => {
        if (value.data.attachments) {
          let tempSurvey: ISurveys = {
            Surveys: [],
            Survey: {
              name: "",
              description: "",
              validFor: 0,
              completionMethod: 0,
              sections: [],
              certificateCount: 0,
              templateCount: 0,
              canDelete: false,
            },
            SurveyNames: [],
            ClientNames: [],
            TotalRows: 0,
            IsLoading: false,
            Attachments: [],
          };
          if (value.data.attachments) {
            tempSurvey.Attachments.push(value.data.attachments);
          }
          action.setAttachments(tempSurvey.Attachments);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Something Went wrong."
        );
        action.setIsLoading(false);
      });
  }),
};

export default surveyModel;
