import React, { useEffect, useState } from "react";
import BreadCrumbPage from "../../../common/bread-crumb-page";
import {
    useStoreActions,
    useStoreState,
} from "../../../store/models/model-hook";
import Accordion from "react-bootstrap/Accordion";
import { Col, Row, Container, Form, Nav } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    navContentStyle,
    navStyle,
    tabDivStyle,
} from "../../../common/components-style";
import BrandClientDetailsSideMenu from "../brand-client-details-home/brand-client-details-side-menu";
import { useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import moment from "moment";
import { SpinLoader } from "../../../utils/spin-loader";
import { text } from "node:stream/consumers";

const BrandSurveyDetails: React.FC = (): JSX.Element => {
    let { id, certificateId, surveyId } = useParams();
    const { user } = useAuthenticator((context) => [context.user]); //aws auth
    const [startDate, setStartDate] = useState(String); // local state management

    const { getSurveyDetailsByCertificate } = useStoreActions((actions) => ({
        getSurveyDetailsByCertificate:
            actions.brandSurveyModel.getSurveyDetailsByCertificate,
    }));

    const { getClientById } = useStoreActions((actions) => ({
        getClientById: actions.clientModel.getClientById,
    }));

    const { Client, IsLoading, BrandSurvey } = useStoreState((state) => ({
        Client: state.clientModel.Client,
        IsLoading: state.brandSurveyModel.IsLoading,
        BrandSurvey: state.brandSurveyModel.BrandSurvey,
    }));

    useEffect(() => {
        (async function () {
            await getClientById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: id,
            });
        })();
    }, [getClientById, id, user.attributes?.email]);

    useEffect(() => {
        (async function () {
            await getSurveyDetailsByCertificate({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: certificateId,
                surveyId: surveyId,
            });
        })().then(function () {
            // if (BrandSurvey.expectedCompletedDate) {
            //   setStartDate(
            //     moment(BrandSurvey.expectedCompletedDate, "MM/dd/YYYY").toDate()
            //   );
            // }
            if (BrandSurvey.completedOn) {
                setStartDate(moment(BrandSurvey.completedOn).format("DD MMM YYYY"));
            }
        });
    }, [
        getSurveyDetailsByCertificate,
        user.attributes?.email,
        BrandSurvey.expectedCompletedDate,
        BrandSurvey.completedOn,
        certificateId,
        surveyId,
    ]);
    
    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={tabDivStyle}>
                <Row>
                    <Col sm={2}>
                        <Nav
                            variant="pills"
                            className="flex-column secondry-menu"
                            style={navStyle}
                        >
                            <BrandClientDetailsSideMenu id={id} />
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <div style={navContentStyle}>
                            <BreadCrumbPage
                                selectedBrandCrumb="brandManagement"
                                activateName={`${BrandSurvey.programme} / ${moment(BrandSurvey.startDate).format("DD MMM YYYY")} / ${BrandSurvey.name}`}
                                secondName={Client?.name}
                                secondNameId={Client?.id}
                                secondURL="brand-surveys/"
                            />
                            <Row style={{ marginBottom: "2%" }}>
                                <Col md={2}>
                                    <Form.Label>Completion Date</Form.Label>
                                </Col>
                                <Col md={3} style={{ textAlign: "left" }}>
                                    <input type="text" id="test" value={startDate} disabled />
                                    {/* <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        onChange={(date: Date) => {
                                        setStartDate(date);
                                        }}
                                        disabled
                                    /> */}
                                </Col>
                                <Col md={7}> </Col>
                            </Row>
                            <Row>
                                <Col md={11}>
                                    <Accordion defaultActiveKey="0" flush>
                                        {BrandSurvey.sections.map((e: any, index: any) => (
                                            <Accordion.Item eventKey={index} key={index}>
                                                <Accordion.Header>
                                                    <h4>{e.name}</h4>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Container>
                                                                <div>
                                                                    {/* <div style={{ marginBottom: '5%' }}>
                                                                            <h3>{e.name}</h3>
                                                                        </div> */}
                                                                    {e.questions.map((q: any, index: any) => (
                                                                        <Row key={index}>
                                                                            <Col md={4}>
                                                                                <Form.Label>{q.description}</Form.Label>
                                                                            </Col>
                                                                            <Col md={8}>
                                                                                <p className="lead">
                                                                                    {q.answer?.answer}
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            </Container>
                                                        </Col>
                                                        <Col md={2}></Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BrandSurveyDetails;
