/* eslint-disable new-parens */
import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";
import { ICommonRequestModel } from "../common/common-request";

interface IPropertyRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  id?: string;
}
interface IPropertiesRequest extends ICommonRequestModel {
  ClientID?: string;
  current?: number;
  pageSize?: number;
  sortField?: string;
}

export interface IProperty {
  id?: string;
  name?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  country?: string;
  post_code?: string;
  email?: string;
  contact?: string;
  phone?: string;
}
interface IProperties {
  Properties: IProperty[];
  Property?: IProperty;
  IsLoading: boolean;
  TotalRows: number;
}

interface IPropertiesAction {
  setAllProperty: Action<this, IProperties>;
  setProperty: Action<this, IProperties>;
}
interface IPropertyThunk {
  getAllProperty: Thunk<this, IPropertiesRequest>;
  getPropertyById: Thunk<this, IPropertyRequest>;
  setIsLoading: Action<this, boolean>;
  setTotalRows: Action<this, number>;
}

interface IPropertyModel
  extends IProperties,
    IPropertiesAction,
    IPropertyThunk {}

const propertyModel: IPropertyModel = {
  Properties: [],
  Property: {},
  IsLoading: false,
  TotalRows: 0,
  setAllProperty: action((state, payload) => {
    state.Properties = payload.Properties;
  }),
  setProperty: action((state, payload) => {
    state.Property = payload.Property;
  }),
  getAllProperty: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/building/Search", payload)
      .then((value: any) => {
        let temProperties: IProperties = {
          Properties: [],
          IsLoading: false,
          TotalRows: 0,
        };
        value.data.buildings.forEach((element: any) => {
          temProperties.Properties?.push({
            id: element.id,
            name: element.buildingName,
            email: element?.contactInfo?.email,
            phone: element.telephone,
            contact: element?.contactInfo?.name,
            address_1: element.address1,
            address_2: element.address2,
            city: element.city,
            country: element.country,
            post_code: element.postcode,
          });
        });
        action.setAllProperty(temProperties);
        action.setTotalRows(value.data.totalCount);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  getPropertyById: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/building/Get", payload)
      .then((value: any) => {
        if (value.data.building) {
          let temClient: IProperties = {
            Property: {
              id: value.data.building.id,
              name: value.data.building.buildingName,
              email: value.data.building?.contactInfo?.email,
              phone: value.data.building.telephone,
              contact: value.data.building?.contactInfo?.name,
              address_1: value.data.building.address1,
              address_2: value.data.building.address2,
              city: value.data.building.city,
              country: value.data.building.country,
              post_code: value.data.building.postcode,
            },
            Properties: [],
            IsLoading: false,
            TotalRows: 0,
          };
          action.setProperty(temClient);
          action.setIsLoading(false);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),

  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),
};

export default propertyModel;
