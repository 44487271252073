import React, { useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useStoreActions, useStoreState } from '../../../store/models/model-hook';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import BreadCrumbPage from '../../../common/bread-crumb-page';

const ClientDetails: React.FC = (): JSX.Element => {

    //////////////// handle url /////////////
    let { id } = useParams();

    ////////////// aws auth ///////////////////
    const { user } = useAuthenticator((context) => [context.user]);

    /////////////// global action management ////////////
    const { getClientById } = useStoreActions((actions) => ({
        getClientById: actions.clientModel.getClientById
    }));

    /////////////// global state management ////////////
    const { Client } = useStoreState((state) => ({
        Client: state.clientModel.Client
    }));

    useEffect(() => {
        (async function () {
            await getClientById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string
                },
                id: id,
            });
        }());
    }, [getClientById, id, user.attributes?.email]);

    return (
        <>
            <BreadCrumbPage selectedBrandCrumb="clientManagement" activateName={Client?.name}/>
            <Row style={{ marginTop: '3%' }}>
                <Col md={2}></Col>
                <Col md={8}>
                    <Container>
                        <div className="jumbotron">
                            <Row>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Form.Label>Client Name</Form.Label>
                                </Col>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <p className="lead">{Client?.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Form.Label>Programme</Form.Label>
                                </Col>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <p className="lead">{Client?.programme}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Form.Label>Email Address</Form.Label>
                                </Col>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <p className="lead">{Client?.email}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Form.Label>Contact</Form.Label>
                                </Col>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <p className="lead">{Client?.contact}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{ textAlign: 'right' }}>
                                    <Form.Label>Phone Number</Form.Label>
                                </Col>
                                <Col md={6} style={{ textAlign: 'left' }}>
                                    <p className="lead">{Client?.phone}</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Col>
                <Col md={2}></Col>
            </Row>
        </>
    );

};

export default ClientDetails;