import React, { useCallback, useEffect, useState } from "react";
import {
    Col,
    Nav,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    navContentStyle,
    navStyle,
    tabDivStyle,
    tableStyles,
} from "../../common/components-style";
import { useParams } from "react-router-dom";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";
import BreadCrumbPage from "../../common/bread-crumb-page";
import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import DataTable from "react-data-table-component";
import { overlayTriggerDelay } from "../../common/components-funtion";
import eyeIcon from "../../images/table/eye.svg";
import { useAuthenticator } from "@aws-amplify/ui-react";
import moment from "moment";
import { SpinLoader } from "../../utils/spin-loader";

const RFPSurvey: React.FC = (): JSX.Element => {
    let { id, surveyId } = useParams();
    const { user } = useAuthenticator((context) => [context.user]);
    const { register, setValue } = useForm();
    const [clientId, setClientId] = useState('')

    const { RFPTemplateBrand, RFPTemplateSurveyClient, IsSuccessResponse, IsLoading } =
        useStoreState((state) => ({
            RFPTemplateBrand: state.rfpTemplateModel.RFPTemplateBrand,
            RFPTemplateSurveyClient: state.rfpTemplateModel.RFPTemplateSurveyClient,
            IsSuccessResponse: state.rfpTemplateModel.IsSuccessResponse,
            IsLoading: state.rfpTemplateModel.IsLoading,
        }));

    const {
        getRFPBrandsBySurvey,
        setIsSuccessResponse,
        resetRFPTemplateSurvey,
    } = useStoreActions((actions) => ({
        getRFPBrandsBySurvey: actions.rfpTemplateModel.getRFPBrandsBySurvey,
        setIsSuccessResponse: actions.rfpTemplateModel.setIsSuccessResponse,
        resetRFPTemplateSurvey: actions.rfpTemplateModel.resetRFPTemplateSurvey,
    }));

    const handleBrandChange = (event: any) => {
        const selectedValue = event.target.value;
        setClientId(selectedValue);
    };

    useEffect(() => {
        (async function () {
            await getRFPBrandsBySurvey({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                templateId: id ? id : '',
                surveyId: surveyId ? surveyId : '',
                clientId: clientId ? clientId : ''
            });
        })();
    }, [getRFPBrandsBySurvey, id, surveyId, clientId, user.attributes?.email]);

    useEffect(() => {
        setValue("RFPClientName", RFPTemplateBrand.corporate_client);
        setValue("Programme", RFPTemplateBrand.programme);
        setValue("rfpStatus", RFPTemplateBrand.rfpStatus);
        setValue("RFPDate", moment(RFPTemplateBrand.rfp_date, "DD/MM/YYYY").format("DD MMM YYYY"));
        setValue("RFPEndDate", moment(RFPTemplateBrand.rfp_EndDate, "DD/MM/YYYY").format("DD MMM YYYY"));
    }, [RFPTemplateBrand, setValue]);

    const handleReset = async (id: any) => {
        console.log("id", id);
        await resetRFPTemplateSurvey({
            security: {
                APIKey: process.env.REACT_APP_API_KEY as string,
                User: user.attributes?.email as string,
            },
            templateId: id ? id : '',
            surveyId: surveyId ? surveyId : '',
            clientId: id 
        });
    };

    const brandColumns = [
        {
            name: "Brand",
            selector: (row: any) => row.brandName,
        },
        {
            name: "Property",
            selector: (row: any) => row.propertyName ? row.propertyName : '-',
        },
        {
            name: "Status",
            cell: (row: any) => {
                if (row.rfpSubmissionStatus === "pending") {
                    return (
                        <span style={{ color: "rgba(245, 158, 11)", fontWeight: "bold" }}>
                            Pending
                        </span>
                    );
                } else if (row.rfpSubmissionStatus === "completed") {
                    return (
                        <span style={{ color: "rgb(45, 189, 182)", fontWeight: "bold" }}>
                            Completed
                        </span>
                    );
                } else if (row.rfpSubmissionStatus === "submitted") {
                    return (
                        <span style={{ color: "rgb(239, 68, 68)", fontWeight: "bold" }}>
                            Submitted
                        </span>
                    );
                }
            },
        },
        {
            name: "Actions",
            button: true,
            cell: (row: any, index: number) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                            />
                        </OverlayTrigger>

                        {row.rfpSubmissionStatus === "completed" && (

                            <>
                                {/* Add your Reset button JSX here */}
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id="tooltip-table-top">Reset</Tooltip>}
                                >
                                    <button onClick={() => handleReset(row.id)}>Reset</button>
                                </OverlayTrigger>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        if (IsSuccessResponse) {
            setIsSuccessResponse(false);
        }
    }, [IsSuccessResponse, setValue, setIsSuccessResponse, IsLoading]);

    const generateKey = (num: number = 100000) => {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';

        for (let i = 0; i < num; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    }

    return (
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu "
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <SpinLoader isLoading={IsLoading} />
                    <div style={navContentStyle}>
                        <BreadCrumbPage
                            selectedBrandCrumb="rfpManagement"
                            activateName={RFPTemplateBrand.surveys[0]?.surveyName}
                            secondName={RFPTemplateBrand.name}
                            secondNameId={RFPTemplateBrand?.id}
                            secondURL={RFPTemplateBrand?.id}
                        />

                        {/* <form className="edit-rfp-form"> */}
                            <Row className="mb-3">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Name </label>
                                    <input
                                        {...register("RFPClientName", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">Programme</label>
                                    <input
                                        {...register("Programme", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">Status</label>
                                    <input
                                        {...register("rfpStatus", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Start Date </label>
                                    <input
                                        {...register("RFPDate", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">End Date </label>
                                    <input
                                        {...register("RFPEndDate", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col sm={4} md={4}>
                                    <select
                                        className="form-select"
                                        onChange={handleBrandChange}
                                    >
                                        <option value="">Select</option>
                                        {RFPTemplateSurveyClient && RFPTemplateSurveyClient.map((brand: any, index: any) => {
                                            return (
                                                <option value={brand.id} key={`${brand.id}-${index}`}>{brand.brandName}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1%" }}>
                                <Col md={12}>
                                    <DataTable
                                        columns={brandColumns}
                                        data={RFPTemplateBrand.brands ? RFPTemplateBrand.brands : []}
                                        pagination={false}
                                        striped={true}
                                        customStyles={tableStyles}
                                        theme="mysaTable"
                                        fixedHeader={true}
                                        noDataComponent={"No records found."}
                                        keyField={generateKey()}
                                    />
                                </Col>
                            </Row>
                        {/* </form> */}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default RFPSurvey;
