import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../common/components-style';
import ClientList from './client-list';

const ClientSideMenu: React.FC = (): JSX.Element => {
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="clients" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu " style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="clients">Clients</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="contactCreate">Contact Create</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="clients" >
                                    <div style={navContentStyle}>
                                            <ClientList/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="contactCreate">
                                    <div style={navContentStyle}>

                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
};

export default ClientSideMenu;