import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import NotificationAlert from "../../../common/notification-alert";
import { ICommonRequestModel } from "../common/common-request";
import moment from "moment";
import IGetByIdRequest from "../common/IGetByIdRequest";
import ICertificateAttachment from "../survey/ICertificateAttachment";

interface IRFPTemplateRequest extends ICommonRequestModel {
    clientID?: string;
    Name?: string;
    current?: number;
    pagesize?: number;
    sortfield?: string;
    isFilterRequired: boolean;
    sortorder?: string;
    rfpTemplateStatus?: string;
}

export interface IGetRFPTemplateResponse {
    id?: string;
    clientName?: string;
    programme?: string;
    rfpDate?: string;
    rfpStatus?: string;
    rfp_EndDate?: string;
    name?: string;
    showName?: string;
}

export interface IRFPTemplateResponse {
    id?: string;
    name?: string;
    corporate_client?: string;
    programme?: string;
    rfp_date?: string;
    rfpStatus?: string;
    rfp_EndDate?: string;
    description?: string;
    expectedCompletionPeriod?: number;
    external?: boolean;
    useExpectedCompletionPeriod?: boolean;
    surveys: IRFPTemplateSurvey[];
}

export interface IRFPTemplateBrandResponse {
    id?: string;
    name?: string;
    corporate_client?: string;
    programme?: string;
    rfp_date?: string;
    rfpStatus?: string;
    rfp_EndDate?: string;
    description?: string;
    expectedCompletionPeriod?: number;
    external?: boolean;
    useExpectedCompletionPeriod?: boolean;
    surveys: IRFPTemplateSurvey[];
    brands?: IRFPTemplateSurveyClient[];
}

export interface IRFPTemplateSurveyClientResponse {
    id?: string;
    brandName?: string;
    propertyName?: string;
    rfpSubmissionStatus?: string;
    createdDate?: string;
}

interface IEditRFPTemplateRequest extends ICommonRequestModel {
    template: IRFPTemplateResponse;
}

interface IRFPBrandsBySurveyRequest extends ICommonRequestModel {
    templateId: string,
    surveyId: string,
    clientId?: string
}

interface IRFPTemplateSurvey {
    corporate_client?: string;
    programme?: string;
    rfp_date?: string;
    rfp_EndDate?:string;
    rfpStatus?:string;
    surveyDescription?: string;
    surveyID?: string;
    surveyName?: string;
    surveyValidFor?: string;
    survey_type?: string;
    unitpercent?: number;
}

interface IRFPTemplateSurveyClient {
    corporate_client?: string;
    programme?: string;
    rfp_date?: string;
    surveyDescription?: string;
    surveyID?: string;
    surveyName?: string;
    surveyValidFor?: string;
    survey_type?: string;
    unitpercent?: number;
}

interface IRFPTemplates {
    RFPTemplates: IGetRFPTemplateResponse[];
    RFPTemplateNames: string[];
    RFPTemplateForSurvey: IGetRFPTemplateResponse[];
    RFPTemplate: IRFPTemplateResponse;
    RFPTemplateBrand: IRFPTemplateBrandResponse;
    RFPTemplateSurveyClient: [];
    TotalRows: number;
    IsSuccessResponse: boolean;
    IsLoading: boolean;
}

interface IRFPTemplateAction {
    setAllTemplates: Action<this, IRFPTemplates>;
    setAllTemplatesForFilter: Action<this, string[]>;
    setRFPTemplate: Action<this, IRFPTemplateResponse>;
    setRFPTemplateBrand: Action<this, IRFPTemplateBrandResponse>;
    setRFPTemplateSurveyClient: Action<this, []>;
    setTotalRows: Action<this, number>;
    setRFPTemplateForSurvey: Action<this, IGetRFPTemplateResponse[]>;
    setIsSuccessResponse: Action<this, boolean>;
    setIsLoading: Action<this, boolean>;
}

interface IRFPTemplateThunk {
    getAllRFPTemplates: Thunk<this, IRFPTemplateRequest>;
    getAllRFPTemplatesForSurvey: Thunk<this, IRFPTemplateRequest>;
    getRFPTemplateById: Thunk<this, IGetByIdRequest>;
    getRFPBrandsBySurvey: Thunk<this, IRFPBrandsBySurveyRequest>;
    // createRFPTemplate: Thunk<this, ICreateSurveyRequest>;
    editRFPTemplate: Thunk<this, IEditRFPTemplateRequest>;
    resetRFPTemplateSurvey: Thunk<this, IRFPBrandsBySurveyRequest>;
    addCertificateAttachment: Thunk<this, ICertificateAttachment>;

}

interface IRFPTemplateModel
    extends IRFPTemplates,
    IRFPTemplateAction,
    IRFPTemplateThunk { }
const rfpTemplateModel: IRFPTemplateModel = {
    RFPTemplates: [],
    RFPTemplateNames: [],
    RFPTemplate: {
        surveys: [],
    },
    RFPTemplateBrand: {
        surveys: [],
        brands: []
    },
    RFPTemplateSurveyClient: [],
    RFPTemplateForSurvey: [],
    TotalRows: 0,
    IsSuccessResponse: false,
    IsLoading: true,
    getAllRFPTemplates: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post("/certificate/template/search", payload)
            .then((value: any) => {
                let tempRFPTemplates: IRFPTemplates = {
                    RFPTemplates: [],
                    RFPTemplateNames: [],
                    RFPTemplateForSurvey: [],
                    RFPTemplateSurveyClient: [],
                    TotalRows: 0,
                    RFPTemplate: {
                        surveys: [],
                    },
                    RFPTemplateBrand: {
                        surveys: [],
                        brands: []
                    },
                    IsSuccessResponse: false,
                    IsLoading: true,
                };
                value.data.templates.forEach((element: any) => {
                    tempRFPTemplates.RFPTemplates?.push({
                        id: element.id,
                        programme: element.programme,
                        clientName: element.corporate_client,
                        rfpDate:
                            element.rfp_date == null
                                ? element.programme
                                : moment(element.rfp_date).format("DD/MM/yyyy"),
                        rfp_EndDate:
                            element.rfp_EndDate == null
                                ? ""
                                : moment(element.rfp_EndDate).format("DD/MM/yyyy"),
                        rfpStatus: element.rfpStatus,
                    });
                });
                action.setAllTemplates(tempRFPTemplates);
                action.setTotalRows(value.data.totalCount);
                if (payload.isFilterRequired) {
                    action.setAllTemplatesForFilter(value.data.templateNames);
                }
                action.setIsLoading(false);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Error from api"
                );
                action.setIsLoading(false);
            });
    }),

    setAllTemplates: action((state, payload) => {
        state.RFPTemplates = payload.RFPTemplates;
    }),

    setTotalRows: action((state, payload) => {
        state.TotalRows = payload;
    }),

    setAllTemplatesForFilter: action((state, payload) => {
        state.RFPTemplateNames = payload;
    }),

    getAllRFPTemplatesForSurvey: thunk((action, payload) => {
        axios
            .post("/certificate/template/search", payload)
            .then((value: any) => {
                let tempRFPTemplates: IRFPTemplates = {
                    RFPTemplates: [],
                    RFPTemplateNames: [],
                    RFPTemplateForSurvey: [],
                    TotalRows: 0,
                    RFPTemplate: {
                        surveys: [],
                    },
                    RFPTemplateBrand: {
                        surveys: [],
                        brands: []
                    },
                    RFPTemplateSurveyClient: [],
                    IsSuccessResponse: false,
                    IsLoading: true,
                };
                value.data.templates.forEach((element: any) => {
                    // if (process.env.REACT_APP_MYSA_TEMPLATE_ID == element.id) {
                    tempRFPTemplates.RFPTemplateForSurvey?.push({
                        id: element.id,
                        name: element.corporate_client,
                        programme: element.programme,
                        clientName: element.corporate_client,
                        rfpDate:
                            element.rfp_date == null
                                ? element.programme
                                : moment(element.rfp_date).format("yyyy-MM-DD"),
                        showName: `${element.corporate_client} (${element.rfp_date == null
                            ? element.programme
                            : moment(element.rfp_date).format("yyyy-MM-DD")})`,
                    });
                    // }
                });

                const templates = tempRFPTemplates.RFPTemplateForSurvey.filter(
                    (element) => {
                        return element.clientName !== null;
                    }
                );

                action.setRFPTemplateForSurvey(templates);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Error from api"
                );
            });
    }),

    setRFPTemplateForSurvey: action((state, payload) => {
        state.RFPTemplateForSurvey = payload;
    }),

    getRFPTemplateById: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post(`/certificate/template/Get`, payload)
            .then((value: any) => {
                let tempRFPTemplate: IRFPTemplates = {
                    RFPTemplates: [],
                    RFPTemplateNames: [],
                    RFPTemplateForSurvey: [],
                    TotalRows: 0,
                    RFPTemplate: {
                        surveys: [],
                    },
                    RFPTemplateBrand: {
                        surveys: [],
                        brands: []
                    },
                    RFPTemplateSurveyClient: [],
                    IsSuccessResponse: false,
                    IsLoading: true,
                };
                // if (value.data.status.errorCode == 0 && value.data.template) {
                if (value.data.template) {
                    tempRFPTemplate.RFPTemplate = {
                        id: value.data.template.id,
                        name: value.data.template.name,
                        corporate_client: value.data.template.corporate_client,
                        programme: value.data.template.programme,
                        rfp_date:
                            value.data.template.rfp_date == null
                                ? value.data.template.programme
                                : moment(value.data.template.rfp_date).format("DD/MM/yyyy"),
                        rfp_EndDate:
                            value.data.template.rfp_EndDate == null
                                ? ""
                                : moment(value.data.template.rfp_EndDate).format("DD/MM/yyyy"),
                        rfpStatus: value.data.template.rfpStatus,
                        description: value.data.template.description,
                        expectedCompletionPeriod:
                            value.data.template.expectedCompletionPeriod,
                        external: value.data.template.external,
                        useExpectedCompletionPeriod:
                            value.data.template.useExpectedCompletionPeriod,
                        surveys: value.data.template.surveys,
                    };
                }

                action.setRFPTemplate(tempRFPTemplate.RFPTemplate);
                action.setIsLoading(false);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Something Went wrong."
                );
                action.setIsLoading(false);
            });
    }),

    getRFPBrandsBySurvey: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post(`/certificate/template/survey/brand`, payload)
            .then((value: any) => {
                let tempRFPTemplate: IRFPTemplates = {
                    RFPTemplates: [],
                    RFPTemplateNames: [],
                    RFPTemplateForSurvey: [],
                    TotalRows: 0,
                    RFPTemplate: {
                        surveys: [],
                    },
                    RFPTemplateBrand: {
                        surveys: [],
                        brands:[],
                    },
                    RFPTemplateSurveyClient: [],
                    IsSuccessResponse: false,
                    IsLoading: true,
                };
                // if (value.data.status.errorCode == 0 && value.data.template) {
                if (value.data.template) {
                    tempRFPTemplate.RFPTemplateBrand = {
                        id: value.data.template.id,
                        name: value.data.template.name,
                        corporate_client: value.data.template.corporate_client,
                        programme: value.data.template.programme,
                        rfp_date:
                            value.data.template.rfp_date == null
                                ? value.data.template.programme
                                : moment(value.data.template.rfp_date).format("DD/MM/yyyy"),
                        rfp_EndDate:
                            value.data.template.rfp_EndDate == null
                                ? ""
                                : moment(value.data.template.rfp_EndDate).format("DD/MM/yyyy"),
                        rfpStatus: value.data.template.rfpStatus,
                        description: value.data.template.description,
                        expectedCompletionPeriod:
                            value.data.template.expectedCompletionPeriod,
                        external: value.data.template.external,
                        useExpectedCompletionPeriod:
                            value.data.template.useExpectedCompletionPeriod,
                        surveys: value.data.template.surveys,
                        brands: value.data.template.clients,
                    };

                    // tempRFPTemplate.RFPTemplateSurveyClient = value.data.clients;
                    action.setRFPTemplateSurveyClient(value.data.clients);
                }
                
                action.setRFPTemplateBrand(tempRFPTemplate.RFPTemplateBrand);
                action.setIsLoading(false);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Something Went wrong."
                );
                action.setIsLoading(false);
            });
    }),

    setRFPTemplate: action((state, payload) => {
        state.RFPTemplate = payload;
    }),

    setRFPTemplateBrand: action((state, payload) => {
        state.RFPTemplateBrand = payload;
    }),

    setRFPTemplateSurveyClient: action((state, payload) => {
        state.RFPTemplateSurveyClient = payload;
    }),

    setIsLoading: action((state, payload) => {
        state.IsLoading = payload;
    }),

    editRFPTemplate: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post("/certificate/template/update", payload)
            .then((value: any) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "success",
                    "RFP template saved successfully.",
                    "Survey"
                );
                action.setIsLoading(false);
                action.setIsSuccessResponse(true);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Something went wrong."
                );
                action.setIsLoading(false);
                action.setIsSuccessResponse(false);
            });
    }),

    addCertificateAttachment: thunk(async (action, payload) => {
        const formData = new FormData();
        formData.append("name", payload?.name ?? '');
        formData.append("file", payload.file ?? '');
        formData.append("surveyId", payload.surveyId ?? '');
        formData.append("User", payload.security?.User ?? '');
        formData.append("APIKey", payload.security?.APIKey ?? '');
        const response = await axios({
            method: "post",
            url: "/certificate/template/AddAttachmentFile",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((value: any) => {
            // if (value.data.status.errorCode === 0) {
            //   const notification = new NotificationAlert();
            //   notification.createNotification(
            //     "success",
            //     "Document Added successfully.",
            //     "Survey"
            //   );
            // } else {
            //   const notification = new NotificationAlert();
            //   notification.createNotification(
            //     "danger",
            //     value.data.status.message,
            //     "Something Went wrong."
            //   );
            // }
        })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Something Went wrong."
                );
                action.setIsLoading(false);
            });
    }),

    setIsSuccessResponse: action((state, payload) => {
        state.IsSuccessResponse = payload;
    }),

    resetRFPTemplateSurvey: thunk((action, payload) => {
        action.setIsLoading(true);
        axios
            .post("/certificate/template/survey/reset", payload)
            .then((value: any) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "success",
                    "Template survey reset successfully.",
                    "Survey"
                );
                action.setIsLoading(false);
                action.setIsSuccessResponse(true);
            })
            .catch((error) => {
                const notification = new NotificationAlert();
                notification.createNotification(
                    "danger",
                    error.message,
                    "Something went wrong."
                );
                action.setIsLoading(false);
                action.setIsSuccessResponse(false);
            });
    }),
};

export default rfpTemplateModel;
