import { useAuthenticator } from "@aws-amplify/ui-react";
import { useCallback, useEffect } from "react";
import useState from "react-usestateref";
import { Col, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbPage from "../../../../common/bread-crumb-page";
import { overlayTriggerDelay } from "../../../../common/components-funtion";
import {
    navContentStyle,
    navStyle,
    tabDivStyle,
    tabInside,
    tableStyles,
} from "../../../../common/components-style";
import {
    useStoreActions,
    useStoreState,
} from "../../../../store/models/model-hook";
import eyeIcon from "../../../../images/table/eye.svg";
import { SpinLoader } from "../../../../utils/spin-loader";
import BrandPropertySideMenu from "../brand-property-side-menu";
import { SetTableOptions } from "../../../../shared/set-table-options";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";

const BrandPropertySurveyList: React.FC = (): JSX.Element => {
    let { id, propertyId } = useParams();
    const { user } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate(); // navigate
    const [loading, setLoading] = useState(false);
    const [brandRfpStatus, setBrandRfpStatus] = useState("all");
    const [, setSearchKey, searchKeyRef] = useState<String>("");

    const { Client, IsLoading, BrandSurveys, Property, SurveyNames } =
        useStoreState((state) => ({
            Client: state.clientModel.Client,
            IsLoading: state.brandSurveyModel.IsLoading,
            BrandSurveys: state.brandSurveyModel.BrandSurveys,
            Property: state.propertyModel.Property,
            SurveyNames: state.brandSurveyModel.SurveyNames,
        }));

    const { getSurveysByBrand, getClientById } = useStoreActions((actions) => ({
        getSurveysByBrand: actions.brandSurveyModel.getSurveysByBrand,
        getClientById: actions.clientModel.getClientById,
    }));

    const brandPropertySurveyList = useCallback(
        (isFilterRequired: boolean = false) => {
            setLoading(true);
            (async function () {
                await getSurveysByBrand({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    buildingID: propertyId,
                    clientID: "",
                    showExternal: true,
                    surveyType: "PROPERTY_SURVEY",
                    name: searchKeyRef?.current?.replace("/s/g", "") as string,
                    isFilterRequired: isFilterRequired,
                    brandRfpStatus: brandRfpStatus
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getSurveysByBrand, user.attributes?.email, propertyId, searchKeyRef, brandRfpStatus]
    );

    useEffect(() => {
        brandPropertySurveyList(true);
    }, [brandPropertySurveyList]);

    useEffect(() => {
        (async function () {
            await getClientById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: id,
            });
        })();
    }, [getClientById, id, user.attributes?.email]);

    const columns = [
        {
            name: "Client",
            selector: (row: any) => row.clientName,
        },
        {
            name: "Survey Name",
            selector: (row: any) => row.surveyName,
        },
        {
            name: "Programme",
            selector: (row: any) => row.programme,
        },
        {
            name: "RFP Date",
            selector: (row: any) => moment(row.rfpDate, "DD/MM/YYYY").format("DD MMM YYYY"),
            sortable: true,
        },
        {
            name: "Status",
            cell: (row: any) => {
                if (row.isCompleted) {
                    return (
                        <span style={{ color: "rgb(45, 189, 182)", fontWeight: "bold" }}>
                            Completed
                        </span>
                    );
                } else {
                    return (
                        <span style={{ color: "#fAC90E", fontWeight: "bold" }}>
                            Pending
                        </span>
                    );
                }
            },
        },
        {
            name: "Actions",
            button: true,
            cell: (row: any) => {
                if (row.isCompleted) {
                    return (
                        <>
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                            >
                                <img
                                    className="iconSizes"
                                    src={eyeIcon}
                                    alt=""
                                    onClick={() => {
                                        navigate(`/brand-management/brand-survey/${id}/${row.certificateId}/${row.surveyId}`
                                        );
                                    }}
                                />
                            </OverlayTrigger>
                        </>
                    );
                }
            },
        },
    ];

    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={tabDivStyle}>
                <Row>
                    <Col sm={2}>
                        <Nav
                            variant="pills"
                            className="flex-column secondry-menu "
                            style={navStyle}
                        >
                            <BrandPropertySideMenu clientId={id} propertyId={propertyId} />
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <div style={navContentStyle}>
                            <BreadCrumbPage
                                selectedBrandCrumb="brandManagement"
                                activateName={`${Property?.name} - ${Property?.city}`}
                                secondName={Client?.name}
                                secondNameId={Client?.id}
                                secondURL="brand-properties/"
                            />
                            <div style={tabInside}>
                                <Row style={{ marginTop: "3%" }}>
                                    <Col md={3}>
                                        <Typeahead
                                            id="rfp-list-filter"
                                            labelKey="name"
                                            clearButton
                                            onChange={(options: any) => {
                                                if (options && options.length) {
                                                    let brand = options[0];
                                                    setSearchKey(brand);
                                                } else {
                                                    setSearchKey("");
                                                }

                                                brandPropertySurveyList();
                                            }}
                                            options={SurveyNames}
                                            placeholder="Select a Client"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        {/* Here I was added string to filter the status because of 
                                            there is multiple column to fatch the data based on value. */}
                                        <select className="form-select"
                                            onChange={((event: any) => {
                                                setBrandRfpStatus(event.target.value);
                                            })}
                                            value={brandRfpStatus}
                                        >
                                            {/* <option value="">Select Status</option> */}
                                            <option value="all">All Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="completed">Completed</option>
                                            <option value="archive">Archived</option>
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "1%" }}>
                                    <Col md={12}>
                                        <DataTable
                                            columns={columns}
                                            data={BrandSurveys}
                                            pagination
                                            striped={true}
                                            customStyles={tableStyles}
                                            theme="mysaTable"
                                            fixedHeader={true}
                                            noDataComponent={<SetTableOptions loading={loading} />}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BrandPropertySurveyList;
