import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect } from "react";
import {
    Button,
    Col,
    Stack,
    Row,
    OverlayTrigger,
    Tooltip,
    ListGroup,
    Nav,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import BreadCrumbPage from "../../common/bread-crumb-page";
import { overlayTriggerDelay } from "../../common/components-funtion";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import ISurveySection from "../../store/models/survey/ISurveySection";
import QuestionAddEditPopUp from "./question-add-edit-popup";
import SectionCreatePopUp from "./section-create-popup";
import "./survey.css";
import eyeIcon from "../../images/table/eye.svg";
import deleteIcon from "../../images/table/trash.svg";
import ISurveys from "../../store/models/survey/ISurveys";
import { Trash, XCircle, CheckCircle, JustifyRight } from "react-bootstrap-icons";
import { IQuestion } from "../../store/models/survey/IQuestion";
import useState from "react-usestateref"; // see this line
import { useParams } from "react-router-dom";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";
import {
    navContentStyle,
    navStyle,
    tabDivStyle,
} from "../../common/components-style";
import { SpinLoader } from "../../utils/spin-loader";
import ConfirmationModal from "../../shared/confirmation-modal";
import moment from "moment";
import { ISurveyResponse } from "../../store/models/survey/ISurveyResponse";

const SurveyDetails: React.FC<any> = (): JSX.Element => {
    let { id } = useParams();
    const { user } = useAuthenticator((context) => [context.user]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setFocus,
    } = useForm();
    const { Survey, AllSurvey, IsLoading, Attachments } = useStoreState(
        (state) => ({
            Survey: state.surveyModel.Survey,
            AllSurvey: state.surveyModel.Surveys,
            IsLoading: state.surveyModel.IsLoading,
            Attachments: state.surveyModel.Attachments,
        })
    );

    const { getSurveyById, setSurvey, editSurvey, updateCompleteStatus, GetCertificateAttachmentFile, addCertificateAttachment } = useStoreActions(

        (actions) => ({
            getSurveyById: actions.surveyModel.getSurveyById,
            setSurvey: actions.surveyModel.setSurvey,
            editSurvey: actions.surveyModel.editSurvey,
            updateCompleteStatus: actions.surveyModel.updateCompleteStatus,
            GetCertificateAttachmentFile: actions.surveyModel.GetCertificateAttachmentFile,
            addCertificateAttachment: actions.rfpTemplateModel.addCertificateAttachment,
        })
    );

    const getSurvey = useCallback(() => {
        (async function () {
            await getSurveyById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: id,
            });
        })();
    }, [getSurveyById, id, user.attributes?.email]);

    useEffect(() => {
        getSurvey();
    }, [getSurvey]);

    const [currentSection, setCurrentSection] = useState<ISurveySection>({
        name: "",
        description: "",
        isCompleted: false,
        questions: [],
        qaPass: false,
    });
    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(-1);
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
    const [isEditSectionModeOn, setIsEditSectionModeOn] =
        useState<boolean>(false);
    const [deleteSectionModalShow, setDeleteSectionModalShow] =
        useState<boolean>(false);
    const [deleteQuestionModalShow, setDeleteQuestionModalShow] =
        useState<boolean>(false);
    const [isPageLoadEvent, setIsPageLoadEvent] = useState<boolean>(true);

    useEffect(() => {
        //set survey form
        setValue("SurveyName", Survey.name);
        setValue("SurveyType", Survey.survey_type);
        setValue("Description", Survey.description);

        if (isPageLoadEvent) {
            //set current section form
            if (Survey.sections?.length > 0) {
                //set default survey section and question details
                //  setCurrentSection(Survey.sections[0]);
                // setCurrentSectionIndex(0);
                if (currentSectionIndex === -1) {
                    setCurrentSection(Survey.sections[0]);
                    setCurrentSectionIndex(0);
                } else {
                    setCurrentSection(Survey.sections[currentSectionIndex]);
                    setCurrentSectionIndex(currentSectionIndex);
                }
            } else {
                setCurrentSection({
                    name: "",
                    description: "",
                    isCompleted: false,
                    questions: [],
                    qaPass: false,
                });
                setCurrentSectionIndex(-1);
            }
        }
        else {
            setCurrentSection(Survey.sections[currentSectionIndex]);
        }
    }, [Survey, setCurrentSection, setCurrentSectionIndex, setValue, isPageLoadEvent, currentSectionIndex]);
    // const [sectionCompleted, setSectionCompleted] = useState(false);
    const [showSection, setShowSection] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [queIdToBeDeleted, setQueIdToBeDeleted] = useState<number>(-1);
    const [isEditQuestionModal, setIsEditQuestionModal] =
        useState<boolean>(false);

    const handleCloseSection = () => setShowSection(false);
    const handleShowSection = () => setShowSection(true);
    const handleCloseQuestion = () => setShowQuestion(false);
    const handleShowQuestion = () => setShowQuestion(true);
    const handleDeleteSectionModalClose = () => {
        setDeleteSectionModalShow(false);
    };
    const handleDeleteQuestionModalClose = () => {
        setDeleteQuestionModalShow(false);
        setQueIdToBeDeleted(-1);
    };

    const handleDeleteSectionModalShow = () => setDeleteSectionModalShow(true);
    const handleDeleteQuestionModalShow = () => setDeleteQuestionModalShow(true);

    useEffect(() => {
        if (isEditSectionModeOn) {
            setFocus("SectionName");
        }
    }, [isEditSectionModeOn, setFocus]);

    const editSurveyDetails = useCallback(
        (d: any) => {
            (async function () {
                let survey = Survey;
                
                if (survey) {
                    survey.validFor = d.validFor;
                    survey.useExpectedCompletionPeriod = d.useExpectedCompletionPeriod;
                    survey.expectedCompletionPeriod = d.expectedCompletionPeriod;
                    survey.completionMethod = d.completionMethod;
                    survey.sections = Survey.sections;
                    survey.autoComplete = d.autoComplete;
                    survey.autoCompleteSurveyID = d.autoCompleteSurveyID;
                    survey.certificateCount = d.certificateCount;
                    survey.templateCount = d.templateCount;
                    survey.canDelete = d.canDelete;
                }
                
                await editSurvey({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    Survey: survey,
                });
            })().then(function () {
                setIsEditSectionModeOn(false);
                // getSurvey();
                setIsPageLoadEvent(false);
            });
        },
        //[Survey, editSurvey, user.attributes?.email,getSurvey]
        [Survey, editSurvey, user.attributes?.email]
    );

    const manageSection = useCallback(
        (section: any) => {
            let isCompleted = false;
            if (Survey.status?.toLowerCase() === "released") {
                isCompleted = true;
            }
            Survey.sections.push({
                name: section.SectionName,
                description: section.SectionDescription,
                isCompleted: isCompleted,
                questions: [],
                qaPass: false,
                qaaccepted: false,
            });
            let tempSurvey: ISurveys = {
                Surveys: AllSurvey,
                Survey: Survey,
                TotalRows: 0,
                SurveyNames: [],
                ClientNames: [],
                IsLoading: false,
                Attachments: [],
            };

            let tempCurrentSection: ISurveySection = {
                name: section.SectionName,
                description: section.SectionDescription,
                isCompleted: isCompleted,
                questions: [],
                qaPass: false,
            };

            setCurrentSection(tempCurrentSection);
            setCurrentSectionIndex(Survey?.sections?.length - 1);
            setSurvey(tempSurvey);
            editSurveyDetails(Survey);
            handleCloseSection();
        },
        [Survey, editSurveyDetails, AllSurvey, setSurvey]
    );

    const deleteSection = useCallback(() => {
        let survey = Survey;
        survey.sections.splice(currentSectionIndex, 1);
        let tempSurvey: ISurveys = {
            Surveys: AllSurvey,
            Survey: survey,
            TotalRows: 0,
            SurveyNames: [],
            ClientNames: [],
            IsLoading: false,
            Attachments: [],
        };

        setSurvey(tempSurvey);
        editSurveyDetails(Survey);
        if (Survey.sections?.length > 0) {
            setCurrentSection(Survey.sections[0]);
            setCurrentSectionIndex(0);
        } else {
            setCurrentSection({
                name: "",
                description: "",
                isCompleted: false,
                questions: [],
                qaPass: false,
            });
            setCurrentSectionIndex(-1);
        }

        handleDeleteSectionModalClose();
    }, [
        Survey,
        currentSectionIndex,
        editSurveyDetails,
        setCurrentSectionIndex,
        AllSurvey,
        setSurvey,
    ]);

    const manageQuestion = (question: any) => {
        let options;
        if (question.File !== undefined && question.File.length > 0) {
            options = question.File[0].name;
            addCertificateAttachment({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                file: question.File[0],
            })
        }
        else {
            options = question.options;
        }
        let dummySection = currentSection;
        if (dummySection.questions.length <= currentQuestionIndex) {
            dummySection.questions.push({
                type: parseInt(question.questionType),
                description: question.questionDescription,
                options: options,
                mustApprove: false,
                mandatory: question.mandatory,
                conditional: question.condition,
                conditionalSurveyID: question.questionConditionSurvey,
                conditionalSectionID: question.questionConditionSection,
                conditionalQuestionID: question.questionConditionQuestion,
                conditionalValue: question.conditionValue,
                conditionalSurveyName: "",
                conditionalSectionName: "",
                conditionalQuestionName: "",
                conditionalDisabled: false,
                validate: question.validate,
                validationFailMessage: question.validationFailMessage,
                validationMin: question.validationMin,
                validationMax: question.validationMax,
                validationExtra: question.validationExtra,
                gpsTagged: question.gpsTagged,
                gpsRingfenced: question.gpsRingfenced,
                recentPicture: question.recentPicture,
                recentleaway: question.recentleaway,
            });
        } else {
            let index: number = currentQuestionIndex;
            dummySection.questions[index].type = parseInt(question.questionType);
            dummySection.questions[index].description = question.questionDescription;
            dummySection.questions[index].options = options;
            dummySection.questions[index].mandatory = question.mandatory;
            dummySection.questions[index].conditional = question.condition;
            dummySection.questions[index].conditionalSurveyID =
                question.questionConditionSurvey;
            dummySection.questions[index].conditionalSectionID =
                question.questionConditionSection;
            dummySection.questions[index].conditionalQuestionID =
                question.questionConditionQuestion;
            dummySection.questions[index].conditionalValue = question.conditionValue;
            dummySection.questions[index].validate = question.validate;
            dummySection.questions[index].validationFailMessage =
                question.validationFailMessage;
            dummySection.questions[index].validationMin = question.validationMin;
            dummySection.questions[index].validationMax = question.validationMax;
            dummySection.questions[index].validationExtra = question.validationExtra;
            dummySection.questions[index].gpsTagged = question.gpsTagged;
            dummySection.questions[index].gpsRingfenced = question.gpsRingfenced;
            dummySection.questions[index].recentPicture = question.recentPicture;
            dummySection.questions[index].recentleaway = question.recentleaway;
        }
        if (dummySection.isCompleted == true && Survey.status?.toLowerCase() != "released") {
            dummySection.isCompleted = false;
        }
        setCurrentSection(dummySection);
        Survey.sections[currentSectionIndex] = dummySection; //update current section
        editSurveyDetails(Survey);
        handleCloseQuestion();
    };

    const deleteQuestion = () => {
        if (queIdToBeDeleted >= 0) {
            var survey = Survey;
            survey.sections[currentSectionIndex].questions.splice(
                queIdToBeDeleted,
                1
            );
            let tempSurvey: ISurveys = {
                Surveys: AllSurvey,
                Survey: survey,
                TotalRows: 0,
                SurveyNames: [],
                ClientNames: [],
                IsLoading: false,
                Attachments: [],
            };
            if (survey.sections[currentSectionIndex].isCompleted = true && survey.status?.toLowerCase() != "released") {
                survey.sections[currentSectionIndex].isCompleted = false; //update current section
            }
            setSurvey(tempSurvey);
            editSurveyDetails(Survey);
            handleDeleteQuestionModalClose();
        }
    };

    useEffect(() => {
        setValue("SectionName", currentSection?.name);
        setValue("SectionDescription", currentSection?.description);
    }, [currentSection, setValue]);

    const handleSectionChange = (e: any) => {
        let tempCurrentSection = currentSection;
        tempCurrentSection.name = e.SectionName;
        tempCurrentSection.description = e.SectionDescription;
        let survey = Survey;
        let isCompleted = false;
        if (survey.sections[currentSectionIndex].isCompleted = true && Survey.status?.toLowerCase() != "released") {
            survey.sections[currentSectionIndex].isCompleted = false; //update current section
        }
        else {
            isCompleted = true;
        }
        tempCurrentSection.isCompleted = isCompleted;
        setCurrentSection(tempCurrentSection);

        survey.sections[currentSectionIndex] = tempCurrentSection;
        let tempSurvey: ISurveys = {
            Surveys: AllSurvey,
            Survey: survey,
            TotalRows: 0,
            SurveyNames: [],
            ClientNames: [],
            IsLoading: false,
            Attachments: [],
        };
        setSurvey(tempSurvey);
    };

    const cancelEditMode = useCallback(() => {
        setIsEditSectionModeOn(false);
        setValue("SectionName", currentSection?.name);
        setValue("SectionDescription", currentSection?.description);
    }, [currentSection, setValue]);

    const onSubmit = (d: any) => {
        handleSectionChange(d);
        editSurveyDetails(d);
    };

    const completeStatus = (e: any) => {
        let CurrentSectionData = currentSection;
        CurrentSectionData.name = currentSection?.name;
        CurrentSectionData.description = currentSection?.description;
        CurrentSectionData.isCompleted = true;
        setCurrentSection(CurrentSectionData);
        let survey = Survey;
        survey.sections[currentSectionIndex] = CurrentSectionData;
        let tempSurvey: ISurveys = {
            Surveys: AllSurvey,
            Survey: survey,
            TotalRows: 0,
            SurveyNames: [],
            ClientNames: [],
            IsLoading: false,
            Attachments: [],
        };
        setSurvey(tempSurvey);
        editSurveyDetails(Survey);
    }

    const [dragdrop, setDragdrop] = useState<number>(0);

    const handleDragEnter = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragLeave = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragStart = useCallback(
        (e: any) => {
            setDragdrop(parseInt(e.currentTarget.id.substring(1)));
            e.stopPropagation();
        },
        [setDragdrop]
    );

    const handleDragOver = useCallback((e: any) => {
        e.dataTransfer.dropEffect = "move";
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDropQuestion = useCallback(
        (e: any) => {
            var i = parseInt(e.currentTarget.id.substring(1));
            if (i !== dragdrop) {
                let survey = Object.assign(Survey);
                var tempSection = currentSection;
                var questions = tempSection.questions;
                var q;
                //    Move to the first item
                q = questions.splice(dragdrop, 1)[0];
                if (i === 0) {
                    questions.splice(0, 0, q);
                } else {
                    if (dragdrop > i) questions.splice(i, 0, q);
                    else questions.splice(i - 1, 0, q);
                }

                tempSection.questions = questions;
                setCurrentSection(tempSection);
                let tempSurvey: ISurveys = {
                    Surveys: AllSurvey,
                    Survey: survey,
                    TotalRows: 0,
                    ClientNames: [],
                    SurveyNames: [],
                    IsLoading: false,
                    Attachments: [],
                };

                setSurvey(tempSurvey);
                editSurveyDetails(Survey);
            }
            e.preventDefault();
            e.stopPropagation();
        },
        [Survey, AllSurvey, currentSection, dragdrop, setSurvey, editSurveyDetails]
    );

    const handleDropSection = useCallback(
        (e: any) => {
            let i = parseInt(e.currentTarget.id.substring(1));
            if (i !== dragdrop) {
                let survey = Object.assign(Survey);
                let sections = survey.sections;
                let s;

                if (i === 0) {
                    s = sections.splice(dragdrop, 1)[0];
                    sections.splice(0, 0, s);
                } else {
                    s = sections.splice(dragdrop, 1)[0];
                    if (dragdrop > i) sections.splice(i, 0, s);
                    else sections.splice(i - 1, 0, s);
                }

                var tmp: any = [];

                for (i = 0; i < sections.length; i++) {
                    tmp.push(sections[i]);
                }

                survey.sections = tmp;

                setCurrentSection(survey.sections[currentSectionIndex]);
                let tempSurvey: ISurveys = {
                    Surveys: AllSurvey,
                    Survey: survey,
                    TotalRows: 0,
                    SurveyNames: [],
                    ClientNames: [],
                    IsLoading: false,
                    Attachments: [],
                };
                setSurvey(tempSurvey);
                editSurveyDetails(Survey);
            }
            e.preventDefault();
            e.stopPropagation();
        },
        [
            Survey,
            AllSurvey,
            dragdrop,
            setSurvey,
            currentSectionIndex,
            editSurveyDetails,
        ]
    );

    let questionList = (
        <Stack>
            <Row className="mb-3">
                <Col sm={4}>
                    <Button
                        type="button"
                        id="approve-button"
                        className="create-btn"
                        onClick={() => {
                            setCurrentQuestion(undefined);
                            setCurrentQuestionIndex(currentSection?.questions?.length);
                            handleShowQuestion();
                            setIsEditQuestionModal(false);
                        }}
                        disabled={currentSectionIndex < 0}
                    >
                        Create New Question
                    </Button>
                </Col>
            </Row>
            <ListGroup>
                {currentSection?.questions &&
                    currentSection?.questions.map((row, index: number) => {
                        let cond = "";
                        if (row.conditional) {
                            let conditionalSurvey = AllSurvey.find(
                                (survey) => survey.id === row.conditionalSurveyID
                            );
                            let conditionalQuestion;
                            conditionalSurvey?.sections?.forEach((element) => {
                                element.questions.forEach((que) => {
                                    if (que.id === row.conditionalQuestionID) {
                                        conditionalQuestion = que.description;
                                        return false;
                                    }
                                });
                            });
                            cond = "Conditional";
                            cond += " ";
                            if (row.conditionalSurveyID === Survey.id) {
                                cond += "this survey : ";
                            } else {
                                cond += conditionalSurvey?.name + " : ";
                            }

                            cond += " " + conditionalQuestion;
                        }

                        let approveimage = row.mustApprove
                            ? "approve.png"
                            : "noapprove.png";

                        return (
                            <ListGroup.Item
                                draggable={true}
                                onDrop={handleDropQuestion}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                id={`Q${index}`}
                                key={`Q${index}`}
                            >
                                <div className="question-list">
                                    <div className="question-type-image">
                                        <img
                                            className=""
                                            src={`/images/icons/questiontypes/${row.type}.png`}
                                            alt=""
                                        />
                                    </div>

                                    <div className="question-content">
                                        <div className="question-description">
                                            {row.description}
                                        </div>
                                        <div className="question-index">
                                            <Stack>
                                                <div>{row.mandatory ? "Mandatory" : "Optional"}</div>
                                                <div>{cond}</div>
                                            </Stack>
                                        </div>
                                    </div>
                                    <div className="question-type-image">
                                        <img
                                            className=""
                                            src={`/images/icons/${approveimage}`}
                                            alt=""
                                        />
                                    </div>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                                    >
                                        <img
                                            className="iconSizes question-action"
                                            src={eyeIcon}
                                            alt=""
                                            onClick={() => {
                                                setCurrentQuestion(currentSection?.questions[index]);
                                                setCurrentQuestionIndex(index);
                                                handleShowQuestion();
                                                setIsEditQuestionModal(true);
                                            }}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={overlayTriggerDelay}
                                        overlay={<Tooltip id="tooltip-table-top">Delete</Tooltip>}
                                    >
                                        <img
                                            className="iconSizes question-action"
                                            src={deleteIcon}
                                            alt=""
                                            // onClick={() => deleteQuestion(index)}
                                            onClick={() => {
                                                setQueIdToBeDeleted(index);
                                                handleDeleteQuestionModalShow();
                                            }}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </ListGroup.Item>
                        );
                    })}
            </ListGroup>
        </Stack>
    );
    return (
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu"
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <SpinLoader isLoading={IsLoading} />
                    <div style={navContentStyle}>
                        <BreadCrumbPage
                            selectedBrandCrumb="surveyManagement"
                            // activateName={`${Survey.corporate_client} - ${Survey.name} - ${Survey.programme} - ${moment(Survey.rfp_date).format("DD/MM/yyyy")} / ${Survey.name}`}
                            activateName={Survey.corporate_client + " - " + Survey.programme + " - " + moment(Survey.rfp_date).format("DD MMM YYYY") + " / " + Survey.name}
                        />
                        {/* <div style={{ marginBottom: "1%" }}>
                                <h3>Edit Survey</h3>
                            </div> */}
                        <div className="jumbotron">
                            <form
                                className="edit-survey-form"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col md={2}>
                                        <label className="form-label">Survey Name </label>
                                    </Col>
                                    <Col md={10}>
                                        <p className="lead">{Survey.name}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <label className="form-label">Type Of Survey </label>
                                    </Col>
                                    <Col md={10}>
                                        <p className="lead">
                                            {Survey.survey_type === "BRAND_SURVEY"
                                                ? "Brand"
                                                : "Property"}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <label className="form-label">Description </label>
                                    </Col>
                                    <Col md={10}>
                                        <p className="lead">{Survey.description}</p>
                                    </Col>
                                </Row>
                                {/* <Row className="mb-3">
                                        <Col xs={8} md={8}>
                                            <label className="form-label">Survey Name </label>
                                            <input
                                            {...register("SurveyName", { disabled: true })}
                                            className="form-control"
                                            autoComplete="off"
                                            />
                                        </Col>
                                        <Col xs={4} md={4}>
                                            <label className="form-label">Type Of Survey </label>
                                            <select
                                            {...register("SurveyType", { disabled: true })}
                                            className="form-select"
                                            >
                                            <option defaultValue="" value="">
                                                Select
                                            </option>
                                            <option value="BRAND_SURVEY">Brand</option>
                                            <option value="PROPERTY_SURVEY">Property</option>
                                            </select>
                                        </Col>
                                        </Row>
                                        <Row className="mb-3">
                                        <Col xs={8} md={8}>
                                            <label className="form-label">Description</label>
                                            <textarea
                                            {...register("Description", { disabled: true })}
                                            className="form-control"
                                            rows={3}
                                            />
                                        </Col>
                                        <Col xs={4} md={4}></Col>
                                    </Row> */}
                                <hr />
                                <Stack direction="horizontal" gap={3}>
                                    <div className="section-div" style={{ width: "20%" }}>
                                        <Button
                                            type="button"
                                            // style={formButton}
                                            id="approve-button"
                                            className="create-btn"
                                            onClick={handleShowSection}
                                        >
                                            Create New Section
                                        </Button>
                                        <Stack>
                                            {Survey.sections.length === 0 ? (
                                                <Row>
                                                    <Col className="text-center">No records found.</Col>
                                                </Row>
                                            ) : (
                                                Survey.sections.map((key: any, index: any) => (
                                                    // <Row onClick={() =>  selectSelectionFromList(index) }>
                                                    <Row
                                                        key={"I" + index}
                                                        id={"I" + index}
                                                        onDrop={handleDropSection}
                                                        onDragStart={handleDragStart}
                                                        onDragOver={handleDragOver}
                                                        onDragEnter={handleDragEnter}
                                                        onDragLeave={handleDragLeave}
                                                        draggable={true}
                                                        data-is-focusable={true}
                                                        onClick={() => {
                                                            setCurrentSection(Survey.sections[index || 0]);
                                                            setCurrentSectionIndex(index || 0);
                                                        }}
                                                    >
                                                        <Col md={2}>
                                                            {key.qaPass ? <CheckCircle /> : <XCircle />}
                                                        </Col>
                                                        <Col md={10}>{key.name}</Col>
                                                    </Row>
                                                ))
                                            )}
                                        </Stack>
                                    </div>
                                    <div className="vr" />
                                    <div style={{ width: "80%" }}>
                                        <Stack direction="vertical">
                                            <Row className="mb-3">
                                                <Col md={6} style={{ textAlign: "right" }}>
                                                    <label className="form-label">Section Name</label>
                                                </Col>
                                                <Col md={6} style={{ textAlign: "left" }}>
                                                    {isEditSectionModeOn ? (
                                                        <>
                                                            {/* <input
                                                                {...register("isCompleted")}
                                                                className="form-control"
                                                                type={'hidden'}
                                                            /> */}
                                                            <input
                                                                {...register("SectionName", {
                                                                    required: isEditSectionModeOn,
                                                                })}
                                                                className="form-control"
                                                            />
                                                            {errors.SectionName && (
                                                                <span className="error">
                                                                    This field is required
                                                                </span>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <p className="lead">{currentSection?.name}</p>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6} style={{ textAlign: "right" }}>
                                                    <label className="form-label">
                                                        Section Description
                                                    </label>
                                                </Col>
                                                <Col md={6} style={{ textAlign: "left" }}>
                                                    {isEditSectionModeOn ? (
                                                        <>
                                                            <textarea
                                                                {...register("SectionDescription", {
                                                                    disabled: !isEditSectionModeOn,
                                                                })}
                                                                className="form-control"
                                                                rows={3}
                                                            />
                                                        </>
                                                    ) : (
                                                        <p className="lead">
                                                            {currentSection?.description}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                            {currentSection?.isCompleted ? (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col md={6} style={{ textAlign: "right" }}>
                                                            <label className="form-label">
                                                                Section Status
                                                            </label>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: "left" }}>
                                                            <p className="lead">
                                                                {currentSection?.isCompleted ? "Completed" : ""}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <Row className="mb-3">
                                                <Col md={4} style={{ textAlign: "right" }}></Col>
                                                <Col md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    {isEditSectionModeOn ? (
                                                        <>
                                                            <Button
                                                                id="approve-button"
                                                                className="create-btn"
                                                                type="submit"
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                id="reject-button"
                                                                className="cancel-btn"
                                                                onClick={cancelEditMode}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button
                                                            id="reject-button"
                                                            className="cancel-btn"
                                                            onClick={() => {
                                                                setIsEditSectionModeOn(true);
                                                            }}
                                                            disabled={currentSectionIndex < 0}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}

                                                    <Button
                                                        disabled={
                                                            currentSection?.questions?.length > 0 ||
                                                            currentSectionIndex < 0
                                                        }
                                                        // onClick={deleteSection}
                                                        onClick={handleDeleteSectionModalShow}
                                                        className="section-delete-btn cancel-btn"
                                                        id="reject-button"
                                                    >
                                                        <Trash />
                                                        <div className="section-delete-div">Delete</div>
                                                    </Button>

                                                    {!currentSection?.isCompleted ? (
                                                        <>
                                                            <Button
                                                            disabled={
                                                                currentSection?.questions?.length == 0 
                                                            }
                                                                id="approve-button"
                                                                className="cancel-btn btn btn-primary"
                                                                type="button"
                                                                onClick={completeStatus}
                                                            >
                                                                Mark Complete
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <label className="form-label question-title">
                                                Questions
                                            </label>
                                            {questionList}
                                        </Stack>
                                    </div>
                                </Stack>
                            </form>
                        </div>

                        {/* add new section pop up */}
                        <SectionCreatePopUp
                            show={showSection}
                            closeModal={handleCloseSection}
                            manageSection={manageSection}
                        />
                        {/* Add Edit new Question pop up */}
                        <QuestionAddEditPopUp
                            show={showQuestion}
                            question={currentQuestion}
                            allSurveys={AllSurvey}
                            currentSurveyClient={Survey.corporate_client}
                            closeModal={handleCloseQuestion}
                            manageQuestion={manageQuestion}
                            allAttachments={Attachments}
                            isEditModal={isEditQuestionModal}
                            currentSurvey={Survey}
                        />
                        {/* section delete confirmation modal */}
                        <ConfirmationModal
                            show={deleteSectionModalShow}
                            headerText="Delete Section"
                            bodyContent="You are about to delete a section. This action is not reversible."
                            confirmText="Delete"
                            cancelText="Cancel"
                            closeModal={handleDeleteSectionModalClose}
                            confirmed={deleteSection}
                        />
                        {/* question delete confirmation modal */}
                        <ConfirmationModal
                            show={deleteQuestionModalShow}
                            headerText="Delete Question"
                            bodyContent="You are about to delete a question. This action is not reversible."
                            confirmText="Delete"
                            cancelText="Cancel"
                            closeModal={handleDeleteQuestionModalClose}
                            confirmed={deleteQuestion}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SurveyDetails;
