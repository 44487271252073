import { createStore } from "easy-peasy";
import brandSurveyModel from "./models/client/brand-survey/brand-survey-model";
import clientModel from "./models/client/client-model";
import rfpSurveysModel from "./models/client/rfp-surveys-model";
import rfpTemplateModel from "./models/rfp/rfp-template-model";
import { IStoreModel } from "./models/model-hook";
import propertyModel from "./models/properties/property-model";
import surveyModel from "./models/survey/survey-model";

const storeModel: IStoreModel = {
  clientModel: clientModel,
  rfpSurveysModel: rfpSurveysModel,
  propertyModel: propertyModel,
  rfpTemplateModel: rfpTemplateModel,
  surveyModel: surveyModel,
  brandSurveyModel: brandSurveyModel,
};

const store = createStore(storeModel);

export default store;
