import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const ConfirmationModal: React.FC<any> = (props): JSX.Element => {
  const handleClose = () => {
    props.closeModal();
  };

  const handleConfirmation = () => {
    props.confirmed();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        dialogClassName="modal-60w"
        centered
        contentClassName="mysa-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmation-modal-body">
          <Container>
            <Row>
              <Col md={12}>{props.bodyContent}</Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="mb-3">
            <Col>
              <Button id="approve-button" className="create-btn" onClick={handleConfirmation}>
                {props.confirmText}
              </Button>
              <Button
                id="reject-button"
                className="cancel-btn"
                onClick={handleClose}
              >
                {props.cancelText}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
