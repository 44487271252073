import React, { useCallback, useEffect, useState } from "react";
import { Col, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    mysaTableTheme,
    navContentStyle,
    navStyle,
    tabDivStyle,
    tableStyles,
} from "../../../common/components-style";
import DataTable, { createTheme } from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
    useStoreActions,
    useStoreState,
} from "../../../store/models/model-hook";
import { overlayTriggerDelay } from "../../../common/components-funtion";
import eyeIcon from "../../../images/table/eye.svg";
import BreadCrumbPage from "../../../common/bread-crumb-page";
import BrandClientDetailsSideMenu from "../brand-client-details-home/brand-client-details-side-menu";
import { SpinLoader } from "../../../utils/spin-loader";
import { CURRENT_PAGE, PAGE_SIZE } from "../../../utils/constants";
import { SetTableOptions } from "../../../shared/set-table-options";

createTheme("mysaTable", mysaTableTheme, "light");

const BrandProperties: React.FC = (): JSX.Element => {
    let { id } = useParams(); // handle url
    const navigate = useNavigate(); // navigate
    const { user } = useAuthenticator((context) => [context.user]); // aws auth
    const { getAllProperty, getClientById } = useStoreActions((actions) => ({
        getAllProperty: actions.propertyModel.getAllProperty,
        getClientById: actions.clientModel.getClientById,
    }));
    const { Properties, Client, IsLoading, TotalRows } = useStoreState(
        (state) => ({
            Properties: state.propertyModel.Properties,
            Client: state.clientModel.Client,
            IsLoading: state.propertyModel.IsLoading,
            TotalRows: state.propertyModel.TotalRows,
        })
    );

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
    const [perPage, setPerPage] = useState(PAGE_SIZE);

    useEffect(() => {
        (async function () {
            await getClientById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: id,
            });
        })();
    }, [getClientById, id, user.attributes?.email]);

    const getRecords = useCallback(
        (page: number, size = perPage, isFilterRequired: boolean = false) => {
            setLoading(true);
            (async function () {
                await getAllProperty({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    ClientID: id,
                    current: CURRENT_PAGE,
                    pageSize: PAGE_SIZE,
                });
            })().then(() => {
                setLoading(false);
            });
        },
        [getAllProperty, perPage, user.attributes?.email, id]
    );

    useEffect(() => {
        getRecords(CURRENT_PAGE, perPage, true);
    }, [getRecords, perPage]);

    const columns = [
        {
            name: "Building",
            selector: (row: any) => row.name,
        },
        {
            name: "City ",
            selector: (row: any) => row.city,
        },
        {
            name: "Country",
            selector: (row: any) => row.country,
        },
        {
            name: "Action",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/brand-management/property/${id}/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page);
        },
        [getRecords]
    );

    return (
        <>
            <SpinLoader isLoading={IsLoading} />
            <div style={tabDivStyle}>
                <Row>
                    <Col sm={2}>
                        <Nav
                            variant="pills"
                            className="flex-column secondry-menu"
                            style={navStyle}
                        >
                            <BrandClientDetailsSideMenu id={id} />
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <div style={navContentStyle}>
                            <BreadCrumbPage
                                selectedBrandCrumb="brandManagement"
                                activateName={Client?.name}
                            />
                            <Row style={{ marginTop: "3%" }}>
                                <Col md={12}></Col>
                            </Row>
                            <Row style={{ marginTop: "1%" }}>
                                <Col md={12}>
                                    <DataTable
                                        columns={columns}
                                        data={Properties}
                                        striped={true}
                                        customStyles={tableStyles}
                                        theme="mysaTable"
                                        fixedHeader={true}
                                        noDataComponent={<SetTableOptions loading={loading} />}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        progressPending={loading}
                                        progressComponent={<SpinLoader />}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={TotalRows}
                                        paginationDefaultPage={currentPage}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BrandProperties;
