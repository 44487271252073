import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    Col,
    Nav,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    navContentStyle,
    navStyle,
    tabDivStyle,
    tableStyles,
} from "../../common/components-style";
import { useNavigate, useParams } from "react-router-dom";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";
import BreadCrumbPage from "../../common/bread-crumb-page";
import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import DataTable from "react-data-table-component";
import { overlayTriggerDelay } from "../../common/components-funtion";
import trashIcon from "../../images/table/trash.svg";
import eyeIcon from "../../images/table/eye.svg";
import { useAuthenticator } from "@aws-amplify/ui-react";
import moment from "moment";
import IGetSurveysResponse from "../../store/models/survey/IGetSurveysResponse";
import rfpTemplateModel, {
    IRFPTemplateResponse,
} from "../../store/models/rfp/rfp-template-model";
import { SpinLoader } from "../../utils/spin-loader";
import ConfirmationModal from "../../shared/confirmation-modal";

const RFPDetails: React.FC = (): JSX.Element => {
    let { id } = useParams();
    const { user } = useAuthenticator((context) => [context.user]);
    const [shouldAdd, setShouldAdd] = useState<boolean>(false);
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, getValues } = useForm();
    const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState<number>(-1);
    const handleDeleteModalClose = () => {
        setDeleteModalShow(false);
        setIdToBeDeleted(-1);
    };
    const handleDeleteModalShow = () => setDeleteModalShow(true);

    const { RFPTemplate, SurveyNames, IsSuccessResponse, IsLoading } =
        useStoreState((state) => ({
            RFPTemplate: state.rfpTemplateModel.RFPTemplate,
            SurveyNames: state.surveyModel.SurveyNames,
            IsSuccessResponse: state.rfpTemplateModel.IsSuccessResponse,
            IsLoading: state.rfpTemplateModel.IsLoading,
        }));

    const {
        getRFPTemplateById,
        editRFPTemplate,
        getAllSurveyForDropdown,
        setRFPTemplate,
        setIsSuccessResponse,
    } = useStoreActions((actions) => ({
        getRFPTemplateById: actions.rfpTemplateModel.getRFPTemplateById,
        editRFPTemplate: actions.rfpTemplateModel.editRFPTemplate,
        getAllSurveyForDropdown: actions.surveyModel.getAllSurveyForDropdown,
        setRFPTemplate: actions.rfpTemplateModel.setRFPTemplate,
        setIsSuccessResponse: actions.rfpTemplateModel.setIsSuccessResponse,
    }));

    useEffect(() => {
        (async function () {
            await getRFPTemplateById({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                id: id,
            });
        })();
    }, [getRFPTemplateById, id, user.attributes?.email]);

    useEffect(() => {
        (async function () {
            await getAllSurveyForDropdown({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
            });
        })();
    }, [getAllSurveyForDropdown, user.attributes?.email]);

    useEffect(() => {
        //set RFP form
        setValue("RFPClientName", RFPTemplate.corporate_client);
        setValue("Programme", RFPTemplate.programme);
        setValue("rfpStatus", RFPTemplate.rfpStatus);
        setValue("RFPDate", moment(RFPTemplate.rfp_date, "DD/MM/YYYY").format("DD MMM YYYY"));
        setValue("RFPEndDate", RFPTemplate.rfp_EndDate ? moment(RFPTemplate.rfp_EndDate, "DD/MM/YYYY").format("DD MMM YYYY") : "");
    }, [RFPTemplate, setValue]);

    const surveyColumns = [
        {
            name: "Name",
            selector: (row: any) => row.surveyName,
        },
        // {
        //     name: "Client",
        //     selector: (row: any) => row.corporate_client,
        // },
        // {
        //     name: "Programme",
        //     selector: (row: any) => row.programme,
        // },
        // {
        //     name: "Date",
        //     selector: (row: any) =>
        //         row.rfp_date == null
        //             ? row.programme
        //             : moment(row.rfp_date).format("DD MMM YYYY"),
        // },
        {
            name: "Type",
            selector: (row: any) => {
                let status: string = "";
                if (row.survey_type === "BRAND_SURVEY") {
                    status = "Brand";
                } else if (row.survey_type === "PROPERTY_SURVEY") {
                    status = "Property";
                }
                return <>{status}</>;
            },
        },
        {
            name: "Actions",
            button: true,
            cell: (row: any, index: number) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/rfp-management/${RFPTemplate.id}/${row.surveyID}`);
                                }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    const handleSurveyChange = useCallback(
        (event: any) => {
            if (event.target.value) {
                setShouldAdd(true);
            } else {
                setShouldAdd(false);
            }
        },
        [setShouldAdd]
    );

    const onSubmit = () => {
        editRFPDetails();
    };

    const editRFPDetails = useCallback(() => {
        (async function () {
            var tempRFP: IRFPTemplateResponse = { ...RFPTemplate };

            if (tempRFP.rfp_date) {
                tempRFP.rfp_date = moment(tempRFP.rfp_date, "DD/MM/YYYY").format(
                    "yyyy-MM-DD"
                );
            }
            if(tempRFP.rfp_EndDate) {
                tempRFP.rfp_EndDate = moment(tempRFP.rfp_EndDate, "DD/MM/YYYY").format(
                    "yyyy-MM-DD"
                );
            }
            
            await editRFPTemplate({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
                template: tempRFP,
            });
        })();
    }, [RFPTemplate, user.attributes?.email, editRFPTemplate]);

    const addSurveyToList = useCallback(() => {
        let id: string = getValues("Survey");
        if (!id) {
            return false;
        }

        let selectedSurvey = SurveyNames.find((survey) => survey.id === id);
        RFPTemplate.surveys.push({
            surveyID: selectedSurvey?.id,
            surveyName: selectedSurvey?.name,
            corporate_client: selectedSurvey?.corporate_client,
            rfp_date: moment(RFPTemplate.rfp_date, "DD/MM/YYYY").format("yyyy-MM-DD"),
            rfp_EndDate: RFPTemplate.rfp_EndDate ? moment(RFPTemplate.rfp_EndDate, "DD/MM/YYYY").format("yyyy-MM-DD") : "",
            rfpStatus: RFPTemplate.rfpStatus,
            programme: selectedSurvey?.programme,
            survey_type: selectedSurvey?.survey_type,
        });

        setRFPTemplate(RFPTemplate);
        editRFPDetails();
    }, [RFPTemplate, SurveyNames, getValues, setRFPTemplate, editRFPDetails]);

    const deleteSurveyFromList = useCallback(() => {
        if (idToBeDeleted >= 0) {
            RFPTemplate.surveys.splice(idToBeDeleted, 1);
            setRFPTemplate(RFPTemplate);
            handleDeleteModalClose();
            editRFPDetails();
        }
    }, [idToBeDeleted, RFPTemplate, setRFPTemplate, editRFPDetails]);

    useEffect(() => {
        if (IsSuccessResponse) {
            setShouldAdd(false);
            setValue("Survey", "");
            setIsSuccessResponse(false);
        }
    }, [IsSuccessResponse, setValue, setIsSuccessResponse]);
    
    return (
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu "
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <SpinLoader isLoading={IsLoading} />
                    <div style={navContentStyle}>
                        <BreadCrumbPage
                            selectedBrandCrumb="rfpManagement"
                            activateName={RFPTemplate.name}
                        />
                        <div style={{ marginBottom: "1%" }}>
                            {/* <h3>Edit RFP Client</h3> */}
                        </div>
                        <form className="edit-rfp-form" onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Name </label>
                                    <input
                                        {...register("RFPClientName", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">Programme</label>
                                    <input
                                        {...register("Programme", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">Status</label>
                                    <input
                                        {...register("rfpStatus", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4} md={4}>
                                    <label className="form-label">Start Date </label>
                                    <input
                                        {...register("RFPDate", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <label className="form-label">End Date </label>
                                    <input
                                        {...register("RFPEndDate", { disabled: true })}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            {/* <Row className="mb-3">
                                <Col xs={4} md={4}>
                                <Button
                                    id="approve-button"
                                    className="create-btn"
                                    disabled={!shouldSave}
                                    type="submit"
                                >
                                    Save
                                </Button>
                                <Button
                                    id="reject-button"
                                    className="cancel-btn"
                                    onClick={() => {
                                    navigate(`/rfp-management/`);
                                    }}
                                >
                                    {shouldSave ? "Cancel" : "Close"}
                                </Button>
                                </Col>
                            </Row> */}
                            <hr />

                            <Row>
                                <h3>Surveys</h3>
                            </Row>
                            <Row>
                                <Col sm={4} md={4}>
                                    <select
                                        {...register("Survey")}
                                        className="form-select"
                                        onChange={handleSurveyChange}
                                    >
                                        <option value="">Select</option>
                                        {SurveyNames &&
                                            SurveyNames.filter(
                                                (s: any) =>
                                                    // s.corporate_client === RFPTemplate.corporate_client ||
                                                    s.corporate_client === "MYSA"
                                            ).map((e: IGetSurveysResponse, index: number) => {
                                                return (
                                                    <option value={e.id} key={index}>
                                                        {`${e.name} (${(e.survey_type)})`}
                                                    </option>
                                                )
                                            })}
                                    </select>
                                </Col>
                                <Col sm={2} md={2}>
                                    <Button
                                        type="button"
                                        variant="warning"
                                        id="approve-button"
                                        className="create-btn"
                                        onClick={() => {
                                            addSurveyToList();
                                        }}
                                        disabled={!shouldAdd || RFPTemplate.rfpStatus !== "not_started"}
                                    >
                                        Add
                                    </Button>

                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1%" }}>
                                <Col md={12}>
                                    <DataTable
                                        columns={surveyColumns}
                                        data={RFPTemplate.surveys}
                                        pagination={false}
                                        striped={true}
                                        customStyles={tableStyles}
                                        theme="mysaTable"
                                        fixedHeader={true}
                                        noDataComponent={"No records found."}
                                    />
                                </Col>
                            </Row>
                        </form>
                    </div>
                    {/* } */}
                </Col>
            </Row>
            <ConfirmationModal
                show={deleteModalShow}
                headerText="Delete Survey"
                bodyContent="You are about to delete a survey. This action is not reversible."
                confirmText="Delete"
                cancelText="Cancel"
                closeModal={handleDeleteModalClose}
                confirmed={deleteSurveyFromList}
            />
        </div>
    );
};

export default RFPDetails;
