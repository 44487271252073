import React from "react";
import { Route, Routes } from "react-router-dom";
import BrandClientDetails from "../screen/brand-management/brand-client-details-home/brand-client-details";
import BrandContractsList from "../screen/brand-management/brand-client-details-home/brand-contracts-list";
import BrandProperties from "../screen/brand-management/brand-property/brand-properties-list";
import BrandPropertyDetails from "../screen/brand-management/brand-property/brand-property-details/brand-property-details";
import BrandPropertySurveyList from "../screen/brand-management/brand-property/brand-property-survey/brand-property-surveys-list";
import BrandSurveyDetails from "../screen/brand-management/brand-survey/brand-survey-details";
import BrandSurveysList from "../screen/brand-management/brand-survey/brand-surveys-list";

import BrandClientHome from "../screen/brand-management/home/brand-clients-home";
import ClientDetailsHome from "../screen/client-management/client-details-home/clients-details-home";
import ContractHome from "../screen/contract-management/contract-home";
import RFPDetails from "../screen/rfp-management/rfp-details";
import RFPSurvey from "../screen/rfp-management/rfp-survey";

import RFPList from "../screen/rfp-management/rfp-list";
import SurveyDetails from "../screen/survey-management/survey-details";
import SurveyList from "../screen/survey-management/survey-list";
import ClientHome from "./../screen/client-management/home/clients-home";

const Router: React.FC = (): JSX.Element => (
    <Routes>
        <Route path="/brand-management" element={<BrandClientHome />} />
        <Route path="/brand-management/:id" element={<BrandClientDetails />} />
        <Route
            path="/brand-management/brand-contracts/:id"
            element={<BrandContractsList />}
        />
        <Route
            path="/brand-management/brand-surveys/:id"
            element={<BrandSurveysList />}
        />
        <Route
            path="/brand-management/brand-survey/:id/:certificateId/:surveyId"
            element={<BrandSurveyDetails />}
        />
        <Route
            path="/brand-management/brand-properties/:id"
            element={<BrandProperties />}
        />
        <Route
            path="/brand-management/property/:id/:propertyId"
            // element={<BrandPropertyDetailsHome />}
            element={<BrandPropertyDetails />}
        />

        <Route
            path="/brand-management/property-surveys/:id/:propertyId"
            element={<BrandPropertySurveyList />}
        />

        <Route path="/client-management" element={<ClientHome />} />
        <Route path="/client-management/:id" element={<ClientDetailsHome />} />

        <Route path="/survey-management" element={<SurveyList />} />
        <Route path="/survey-management/:id" element={<SurveyDetails />} />

        <Route path="/rfp-management" element={<RFPList />} />
        <Route path="/rfp-management/:id" element={<RFPDetails />} />
        <Route path="/rfp-management/:id/:surveyId" element={<RFPSurvey />} />

        <Route path="/contract-management" element={<ContractHome />} />
    </Routes>
);

export default Router;
