import { Action, action, thunk, Thunk } from "easy-peasy";
import axios from "../../../common/axios";
import Helper from "../../../utils/helper";
import NotificationAlert from "../../../common/notification-alert";
interface IClientRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  id?: string;
}
interface IClientsRequest {
  security?: {
    APIKey: string;
    User: string;
  };
  type?: string;
  current?: number;
  pagesize?: number;
  sortfield?: string;
  Name?: string;
  Email?: string;
  isFilterRequired: boolean;
  filterBrand?: string;
}

export interface IClient {
  id?: string;
  name?: string;
  programme?: string;
  contact?: string;
  phone?: string;
  email?: string;
}
interface IClients {
  Clients: IClient[];
  Client?: IClient;
  ClientNames: string[];
  TotalRows: number;
  IsLoading: boolean;
}

interface IClientAction {
  setAllClientForFilter: Action<this, string[]>;
  setAllClient: Action<this, IClients>;
  setClient: Action<this, IClients>;
  setTotalRows: Action<this, number>;
  setIsLoading: Action<this, boolean>;
}
interface IClientThunk {
  // getAllClientForFilter: Thunk<this, IClientsRequest>;
  getAllClient: Thunk<this, IClientsRequest>;
  getClientById: Thunk<this, IClientRequest>;
}

interface IClientModel extends IClients, IClientAction, IClientThunk {}

const clientModel: IClientModel = {
  Clients: [],
  Client: {},
  ClientNames: [],
  TotalRows: 0,
  IsLoading: true,
  setAllClientForFilter: action((state, payload) => {
    state.ClientNames = payload;
  }),
  setAllClient: action((state, payload) => {
    state.Clients = payload.Clients;
  }),
  setClient: action((state, payload) => {
    state.Client = payload.Client;
  }),
  setTotalRows: action((state, payload) => {
    state.TotalRows = payload;
  }),
  // getAllClientForFilter: thunk((action, payload) => {
  //     axios.post('/client/Search', payload).then((value: any) => {
  //         let temClient: IClients = {
  //             Clients: [],
  //             ClientNames: [],
  //             TotalRows: 0
  //         };
  //         value.data.clients.forEach((element: any) => {
  //             temClient.ClientNames.push(element.name);
  //         });
  //         action.setAllClientForFilter(temClient);
  //     }).catch((error) => {
  //         const notification = new NotificationAlert;
  //         notification.createNotification('danger', error.message, 'Error from api');
  //     });
  // }),
  getAllClient: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/client/Search", payload)
      .then((value: any) => {
        let temClient: IClients = {
          Clients: [],
          ClientNames: [],
          TotalRows: 0,
          IsLoading: false,
        };
        value.data.clients.forEach((element: any) => {
          temClient.Clients?.push({
            id: element.id,
            name: element.name,
            email: element?.contactInfo?.email,
            // phone: element.phone,
            phone: new Helper().formatePhoneNumber(element.phone),
            contact: element?.contactInfo?.name,
            programme: "",
          });
        });

        if (payload.isFilterRequired) {
          action.setAllClientForFilter(value.data.clientNames);
        }

        action.setAllClient(temClient);
        action.setTotalRows(value.data.totalCount);
        action.setIsLoading(false);
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  getClientById: thunk((action, payload) => {
    action.setIsLoading(true);
    axios
      .post("/client/Edit", payload)
      .then((value: any) => {
        if (value.data.client) {
          let temClient: IClients = {
            Client: {
              id: value.data.client.id,
              name: value.data.client.name,
              email: value.data.client?.contactInfo?.email,
              phone: value.data.client.phone,
              contact: value.data.client?.contactInfo?.name,
              programme: "",
            },
            Clients: [],
            ClientNames: [],
            TotalRows: 0,
            IsLoading: false,
          };
          action.setClient(temClient);
          action.setIsLoading(false);
        }
      })
      .catch((error) => {
        const notification = new NotificationAlert();
        notification.createNotification(
          "danger",
          error.message,
          "Error from api"
        );
        action.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, payload) => {
    state.IsLoading = payload;
  }),
};

export default clientModel;
