import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import {
    navStyle,
    tabDivStyle,
    navContentStyle,
} from "../../../common/components-style";
import BrandClientList from "./brand-client-list";
import BrandClientSideMenu from "./brand-client-side-menu";

const BrandClientHome: React.FC = (): JSX.Element => {
    return (
        // <>
        //     <BrandClientSideMenu />
        // </>
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu"
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <div style={navContentStyle}>
                        <BrandClientList />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BrandClientHome;
