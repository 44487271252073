import React, { useEffect, useState } from "react";
import {
  mysaTableTheme,
  navContentStyle,
  navStyle,
  tabDivStyle,
  tableStyles,
} from "../../../common/components-style";
import DataTable, { createTheme } from "react-data-table-component";
import eyeIcon from "../../../images/table/eye.svg";
import {
  Col,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { overlayTriggerDelay } from "../../../common/components-funtion";
import {
  useStoreActions,
  useStoreState,
} from "../../../store/models/model-hook";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import BreadCrumbPage from "../../../common/bread-crumb-page";
import BrandContractsCreate from "./brand-contracts-create";
import BrandClientDetailsSideMenu from "./brand-client-details-side-menu";

createTheme("mysaTable", mysaTableTheme, "light");
const data: any = [];

const BrandContractsList: React.FC = (): JSX.Element => {
  const [create, setCreate] = useState<boolean>(); // local state
  let { id } = useParams(); // handle url
  const { user } = useAuthenticator((context) => [context.user]); // aws auth
  const { getClientById } = useStoreActions((actions) => ({
    getClientById: actions.clientModel.getClientById,
  }));
  const { Client } = useStoreState((state) => ({
    Client: state.clientModel.Client,
  }));

  useEffect(() => {
    (async function () {
      await getClientById({
        security: {
          APIKey: process.env.REACT_APP_API_KEY as string,
          User: user.attributes?.email as string,
        },
        id: id,
      });
    })();
  }, [getClientById, id, user.attributes?.email]);

  const columns = [
    {
      name: "Contract",
      selector: (row: any) => row.contract,
    },
    {
      name: "Client",
      selector: (row: any) => row.client,
      sortable: true,
    },
    {
      name: "Date Signed",
      selector: (row: any) => row.date_signed,
      sortable: true,
    },
    {
      name: "Expires",
      selector: (row: any) => row.expires,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: any) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
            >
              <img
                className="iconSizes"
                src={eyeIcon}
                alt=""
                onClick={() => {}}
              />
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div style={tabDivStyle}>
        <Row>
          <Col sm={2}>
            <Nav
              variant="pills"
              className="flex-column secondry-menu"
              style={navStyle}
            >
              <BrandClientDetailsSideMenu id={id} />
            </Nav>
          </Col>
          <Col sm={10}>
            <div style={navContentStyle}>
              <BreadCrumbPage
                selectedBrandCrumb="brandManagement"
                activateName={Client?.name}
              />
              {create ? (
                <BrandContractsCreate />
              ) : (
                <>
                  <Row style={{ marginTop: "3%" }}>
                    <Col md={3}>
                      <Form>
                        <Form.Control />
                      </Form>
                    </Col>
                    {/* <Col md={2}>
                                                <Button type="submit" id='approve-button' style={formButton}><img src={searchIcon} alt="searchIcon" style={{ height: 20 }} /> Search</Button>
                                            </Col>
                                            <Col md={7} style={{ textAlign: 'right' }}>
                                                <Button type="submit" id='approve-button' style={formButton} onClick={() => { setCreate(true) }}> Upload New Contract</Button>
                                            </Col> */}
                  </Row>
                  <Row style={{ marginTop: "1%" }}>
                    <Col md={12}>
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        // noDataComponent={'Updating records'}
                        noDataComponent={"No record found."}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BrandContractsList;
