import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const BreadCrumbPage: React.FC<any> = (props): JSX.Element => {
  return (
    <>
      {props.selectedBrandCrumb === "clientManagement" && (
        <Row style={{ marginTop: "30px" }}>
          <Col md={12}>
            <Breadcrumb>
              <LinkContainer to="/client-management">
                <Breadcrumb.Item>Client Management</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>{props.activateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      {props.selectedBrandCrumb === "brandManagement" && (
        <Row style={{ marginTop: "30px" }}>
          <Col md={12}>
            <Breadcrumb>
              <LinkContainer to="/brand-management">
                <Breadcrumb.Item>Brand Management</Breadcrumb.Item>
              </LinkContainer>
              {props.secondNameId ? (
                <LinkContainer
                  to={`/brand-management/${props.secondURL || ""}${props.secondNameId}`}
                >
                  <Breadcrumb.Item>{props.secondName}</Breadcrumb.Item>
                </LinkContainer>
              ) : (
                <></>
              )}

              <Breadcrumb.Item active>{props.activateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      {props.selectedBrandCrumb === "property" && (
        <Row style={{ marginTop: "30px" }}>
          <Col md={12}>
            <Breadcrumb>
              <LinkContainer to="/brand-management">
                <Breadcrumb.Item>Brand Management</Breadcrumb.Item>
              </LinkContainer>
              <LinkContainer to={`/brand-management/${props.secondNameId}`}>
                <Breadcrumb.Item>{props.secondName}</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>{props.activateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      {props.selectedBrandCrumb === "surveyManagement" && (
        <Row style={{ marginTop: "30px" }}>
          <Col md={12}>
            <Breadcrumb>
              <LinkContainer to="/survey-management">
                <Breadcrumb.Item>Survey Management</Breadcrumb.Item>
              </LinkContainer>
              <Breadcrumb.Item active>{props.activateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
      {props.selectedBrandCrumb === "rfpManagement" && (
        <Row style={{ marginTop: "30px" }}>
          <Col md={12}>
            <Breadcrumb>
              <LinkContainer to="/rfp-management">
                <Breadcrumb.Item>RFP Management</Breadcrumb.Item>
              </LinkContainer>
              {props.secondNameId ? (
                <LinkContainer
                  to={`/rfp-management/${props.secondURL || ""}`}
                >
                  <Breadcrumb.Item>{props.secondName}</Breadcrumb.Item>
                </LinkContainer>
              ) : (
                <></>
              )}
              <Breadcrumb.Item active>{props.activateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BreadCrumbPage;
