import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  ListGroup,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { overlayTriggerDelay } from "../../common/components-funtion";
import "./survey.css";
import deleteIcon from "../../images/table/trash.svg";

const StringListEdit: React.FC<any> = (props): JSX.Element => {
  const { register, getValues, setValue } = useForm();

  const [list, setList] = useState<string[]>(props.list);

  useEffect(() => {
    setList(props.list.filter((e: string) => e));
  }, [props]);

  const createNewItem = useCallback(() => {
    var l = Object.assign(props.list);
    var newList = l.filter((e: string) => e);
    let option: string = getValues("newString");
    newList.push(option);
    props.onChange(newList);
    setValue("newString", "");
  }, [props, getValues, setValue]);

  const deleteItem = useCallback(
    (index: number) => {
      var l = Object.assign(props.list);
      l.splice(index, 1);
      props.onChange(l);
    },
    [props]
  );

  return (
    <>
      <label className="form-label">Options</label>
      <Row className="mb-3">
        <Col md={6}>
          <input
            {...register("newString")}
            className="form-control"
            autoComplete="off"
          />
        </Col>
        <Col md={6}>
          <Button
            id="approve-button"
            className="create-btn"
            onClick={createNewItem}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Stack className="mb-3">
        <ListGroup>
          {list &&
            list.map((item: string, index: number) => {
              return (
                <ListGroup.Item id={`I${index}`} key={`K${index}`}>
                  <div className="question-list">
                    <div className="question-content">
                      <div className="question-description">{item}</div>
                    </div>
                    <OverlayTrigger
                      placement="top"
                      delay={overlayTriggerDelay}
                      overlay={<Tooltip id="tooltip-table-top">Delete</Tooltip>}
                    >
                      <img
                        className="iconSizes question-action"
                        src={deleteIcon}
                        alt=""
                        onClick={() => deleteItem(index)}
                      />
                    </OverlayTrigger>
                  </div>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Stack>
    </>
  );
};

export default StringListEdit;
