import React from 'react';
import ClientDetailsSideMenu from './client-details-side-menu';


const ClientDetailsHome: React.FC = (): JSX.Element => {
    return (
        <>
           <ClientDetailsSideMenu/>
        </>
    )
};

export default ClientDetailsHome;