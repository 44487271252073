import React, { useCallback, useEffect } from "react";
import {
    Button,
    Col,
    Form,
    Nav,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { overlayTriggerDelay } from "../../common/components-funtion";
import {
    formButton,
    navContentStyle,
    navStyle,
    pageHeader,
    tabDivStyle,
    tabInside,
    tableStyles,
} from "../../common/components-style";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import useState from "react-usestateref";
import moment from "moment";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";
import "./survey.css";
import SurveyCreate from "./survey-create";
import IGetSurveysResponse from "../../store/models/survey/IGetSurveysResponse";
import eyeIcon from "../../images/table/eye.svg";
// import trashIcon from "../../images/table/trash.svg";
// import activatedIcon from "../../images/table/activated.svg";
import deactivatedIcon from "../../images/table/deactivated.svg";
import { Typeahead } from "react-bootstrap-typeahead";
import { FileEarmark, Archive } from "react-bootstrap-icons";
import { SpinLoader } from "../../utils/spin-loader";
import ConfirmationModal from "../../shared/confirmation-modal";
import { SetTableOptions } from "../../shared/set-table-options";
import { CURRENT_PAGE } from "../../utils/constants";
import { useForm } from "react-hook-form";

const SurveyList: React.FC = (): JSX.Element => {
    const { register } = useForm();
    let { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuthenticator((context) => [context.user]);
    const [show, setShow] = useState(false);
    // const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [confirmationApproveModalShow, setConfirmationApproveModalShow] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [copyFromSurveyId, setCopyFromSurveyId] = useState("");
    const [isCreate, setIsCreate] = useState(true);
    const [, setSearchKey, searchKeyRef] = useState<String>("");
    const [, setStatus, statusRef] = useState<String>("0");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const handleConfirmationModalShow = () => setConfirmationModalShow(true);
    const handleConfirmationApproveModalShow = () => setConfirmationApproveModalShow(true);
    // const [idToBeArchived, setIdToBeArchived] = useState<string>("");
    const [idToBeApproved, setIdToBeApproved] = useState<string>("");
    // const handleConfirmationModalClose = () => {
    //     setConfirmationModalShow(false);
    //     setIdToBeArchived("");
    // };
    const handleConfirmationApproveModalClose = () => {
        setConfirmationApproveModalShow(false);
        setIdToBeApproved("");
    };
    const [editSurvey, setEditSurvey] = useState<IGetSurveysResponse>();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { Surveys, TotalRows, ClientNames, IsLoading } = useStoreState(
        (state) => ({
            Surveys: state.surveyModel.Surveys,
            TotalRows: state.surveyModel.TotalRows,
            ClientNames: state.surveyModel.ClientNames,
            IsLoading: state.surveyModel.IsLoading,
        })
    );

    const { getAllSurveys, createSurvey, getAllSurveyForDropdown, archiveSurvey, updateApprovedStatus } =
        useStoreActions((actions) => ({
            getAllSurveys: actions.surveyModel.getAllSurveys,
            createSurvey: actions.surveyModel.createSurvey,
            getAllSurveyForDropdown: actions.surveyModel.getAllSurveyForDropdown,
            archiveSurvey: actions.surveyModel.archiveSurvey,
            updateApprovedStatus: actions.surveyModel.updateApprovedStatus,
        }));

    // const approvedStatus = (id: any) => {
    //     updateApprovedStatus({
    //         security: {
    //             APIKey: process.env.REACT_APP_API_KEY as string,
    //             User: user.attributes?.email as string,
    //         },
    //         id: id,
    //     });
    //     setCurrentPage(1);
    //     getRecords(1);
    //     handleConfirmationApproveModalClose();
    // };

    const getRecords = useCallback(
        (page: number, size = perPage, isFilterRequired: boolean = true) => {
            setLoading(true);
            (async function () {
                await getAllSurveys({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    clientID: id,
                    current: page,
                    pagesize: size,
                    sortfield: "",
                    Name: searchKeyRef?.current?.replace("/s/g", "") as string,
                    isFilterRequired: isFilterRequired,
                    isArchived: statusRef?.current === "0" ? false : true,
                });
            })().then(function () {
                setLoading(false);
            });
        },
        [getAllSurveys, perPage, searchKeyRef, statusRef, user.attributes?.email, id]
    );

    useEffect(() => {
        if(!show) {
           getRecords(CURRENT_PAGE, perPage, true);
        }
    }, [getRecords, perPage, show]);

    useEffect(() => {
        (async function () {
            await getAllSurveyForDropdown({
                security: {
                    APIKey: process.env.REACT_APP_API_KEY as string,
                    User: user.attributes?.email as string,
                },
            });
        })();
    }, [getAllSurveyForDropdown, user.attributes?.email]);

    const create = useCallback(
        (d: any) => {
            (async function () {
                await createSurvey({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },

                    survey: [
                        {
                            Name: d.SurveyName,
                            Description: d.Description,
                            ClientID: d.clientId,
                            Survey_Type: d.SurveyType,
                            Corporate_Client: d.corporateClient,
                            RFP_Date: isCopy
                                ? moment(d.rfpDate, "DD/MM/YYYY").format("yyyy-MM-DD")
                                : d.rfpDate,
                            Programme: d.programme,
                            RFPTemplateId: d.rfpTemplateId
                        },
                    ],
                    IsCopy: isCopy,
                    CopyFromSurveyId: copyFromSurveyId,
                });
            })().then(function () {
                setCurrentPage(1);
                getRecords(1);
                handleClose();
            });
        },
        [isCopy, copyFromSurveyId, user.attributes?.email, createSurvey, getRecords]
    );

    const copySurvey = (id: any) => {
        setEditSurvey(Surveys.find((s) => s.id === id));
    };

    const columns = [
        {
            name: "Client",
            selector: (row: any) => row.clientName,
            width: "20%",
        },
        {
            name: "Survey Name",
            selector: (row: any) => row.surveyName,
            width: "18%",
        },
        {
            name: "Programme",
            selector: (row: any) => row.programme,
            width: "15%",
        },
        {
            name: "RFP Name",
            selector: (row: any) => row.rfpName,
            width: "15%",
        },
        {
            name: "RFP Date",
            selector: (row: any) => moment(row.rfpDate, "DD/MM/YYYY").format("DD MMM YYYY"),
            width: "12%",
        },
        {
            name: "Survey Type",
            selector: (row: any) => row.typeOfSurvey,
            width: "12%",
        },
        {
            name: "Status",
            // selector: (row: any) => row.description,
            selector: (row: any) => {
                let val: "";
                switch (row.status) {
                    case "COMPLETED":
                        return (
                            <span style={{ color: "rgb(45, 189, 182)", fontWeight: "bold" }}>
                                Completed
                            </span>
                        )
                    case "RELEASED":
                        return (
                            <span
                                style={{ color: "rgb(45, 189, 182)", fontWeight: "bold" }}
                                title={`${row.releaseDate && moment(row.releaseDate, "DD/MM/YYYY").format("DD MMM YYYY")}`}
                            >
                                Released
                            </span>
                        )
                    default:
                        return (
                            <span style={{ color: "#fAC90E", fontWeight: "bold" }}>
                                Pending
                            </span>
                        )
                }
            },
            width: "10%",
        },
        {
            name: "Actions",
            button: true,
            style: [{ "justify-content": "left" }],
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/survey-management/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                        {row.clientName?.toLowerCase() === "mysa" &&
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id="tooltip-table-top">Copy</Tooltip>}
                            >
                                <FileEarmark
                                    className="iconSizes"
                                    onClick={() => {
                                        setIsCopy(true);
                                        setCopyFromSurveyId(row.id);
                                        setIsCreate(false);
                                        handleShow();
                                        copySurvey(row.id);
                                    }}
                                />
                            </OverlayTrigger>
                        }
                        {
                            row.status === 'COMPLETED' && 
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id="tooltip-table-top">Activate</Tooltip>}
                            >
                                <img
                                    className="iconSizes"
                                    src={deactivatedIcon}
                                    alt=""
                                    onClick={() => {
                                        setIdToBeApproved(row.id);
                                        handleConfirmationApproveModalShow();
                                    }}
                                />
                            </OverlayTrigger>
                        }
                        {/* {
                            !row.isApproved && row.status?.toLowerCase() != "released" && row.isAllSectionCompleted ? (
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id="tooltip-table-top">Activated</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={deactivatedIcon}
                                        alt=""
                                        onClick={() => {
                                            setIdToBeApproved(row.id);
                                            // approvedStatus(row.id);
                                            handleConfirmationApproveModalShow();
                                        }}
                                    />
                                </OverlayTrigger>
                            ) : row.isApproved && row.status?.toLowerCase() == "released" ? (
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id="tooltip-table-top">Released</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={activatedIcon}
                                        alt=""
                                    />
                                </OverlayTrigger>
                            ) : (<></>)
                        } */}
                    </>
                );
            },
        },
    ];

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page);
        },
        [getRecords]
    );

    // const archiveSurveyById = useCallback(() => {
    //     if (idToBeArchived) {
    //         (async function () {
    //             await archiveSurvey({
    //                 security: {
    //                     APIKey: process.env.REACT_APP_API_KEY as string,
    //                     User: user.attributes?.email as string,
    //                 },
    //                 id: idToBeArchived,
    //             });
    //         })().then(() => {
    //             setCurrentPage(1);
    //             getRecords(1);
    //             handleConfirmationModalClose();
    //         });
    //     }
    // }, [idToBeArchived, archiveSurvey, user.attributes?.email, getRecords]);

    const approveSurveyById = useCallback(() => {
        if (idToBeApproved) {
            (async function () {
                await updateApprovedStatus({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    id: idToBeApproved,
                });
            })().then(() => {
                setTimeout(function () {
                    setCurrentPage(1);
                    getRecords(1);
                    handleConfirmationApproveModalClose();
                }.bind(this), 500);
            });
        }
    }, [idToBeApproved, updateApprovedStatus, user.attributes?.email, getRecords]);

    return (
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu"
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <SpinLoader isLoading={IsLoading} />
                    <div style={navContentStyle}>
                        <div style={pageHeader}>
                            <h3>Survey Management</h3>
                        </div>
                        {
                            <div style={tabInside}>
                                <Form>
                                    <Row style={{ marginTop: "3%" }}>
                                        <Col md={3}>
                                            <Typeahead
                                                id="survey-list-filter"
                                                labelKey="corporate_client"
                                                clearButton
                                                onChange={(options: any) => {
                                                    if (options && options.length) {
                                                        let brand = options[0];
                                                        setSearchKey(brand);
                                                    } else {
                                                        setSearchKey("");
                                                    }

                                                    setCurrentPage(1);
                                                    getRecords(1);
                                                }}
                                                options={ClientNames}
                                                placeholder="Select a Client"
                                            />
                                        </Col>
                                        <Col md={2} className="text-right">
                                            <label className="form-label">Survey Status</label>
                                        </Col>
                                        <Col md={2}>
                                            <select className="form-select"
                                                {...register("SurveyStatus")}
                                                onChange={((event: any) => {
                                                    setStatus(event.target.value);
                                                    setCurrentPage(1);
                                                    getRecords(1);
                                                })}
                                            >
                                                <option value="0" defaultValue="0">Current</option>
                                                <option value="1">Released</option>
                                            </select>
                                        </Col>
                                        <Col offset-md={4}></Col>
                                        {statusRef.current === "0" && 
                                            <Col md={3}>
                                                <Button
                                                    type="button"
                                                    id="approve-button"
                                                    style={formButton}
                                                    onClick={() => {
                                                        setIsCreate(true);
                                                        setIsCopy(false);
                                                        setCopyFromSurveyId("");
                                                        handleShow();
                                                    }}
                                                >
                                                    Create New Survey
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                </Form>
                                <Row style={{ marginTop: "1%" }}>
                                    <Col md={12}>
                                        <DataTable
                                            columns={columns}
                                            data={Surveys}
                                            striped={true}
                                            customStyles={tableStyles}
                                            theme="mysaTable"
                                            fixedHeader={true}
                                            noDataComponent={<SetTableOptions loading={loading} />}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={TotalRows}
                                            paginationDefaultPage={currentPage}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <SurveyCreate
                show={show}
                isCopy={isCopy}
                isCreate={isCreate}
                email={user.attributes?.email}
                clientId={id}
                Surveys={Surveys}
                editSurvey={editSurvey}
                closeModal={handleClose}
                createRecord={create}
            />
            {/* <ConfirmationModal
                show={confirmationModalShow}
                headerText="Archive Survey"
                bodyContent="You are about to archive a survey. This action is not reversible."
                confirmText="Archive"
                cancelText="Cancel"
                closeModal={handleConfirmationModalClose}
                confirmed={archiveSurveyById}
            /> */}
            <ConfirmationModal
                show={confirmationApproveModalShow}
                headerText="Approve Survey"
                bodyContent="You are about to Approve a survey. This action is not reversible."
                confirmText="Approve"
                cancelText="Cancel"
                closeModal={handleConfirmationApproveModalClose}
                confirmed={approveSurveyById}
            />
        </div>
    );
};

export default SurveyList;
